import { useEffect, useState } from 'react';

import { AspectRatioView } from 'components';
import { useAppStateActions, useTranslation } from 'appState';
import s from './GetReady.module.scss';

const GetReady: React.FC = () => {
  const { gameFlowForward } = useAppStateActions();
  const lang = useTranslation('GetReady');
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (countdown === 0) return;
    const timeoutRef = setTimeout(
      () => setCountdown(c => c - 1),
      1000,
    );
    return () => clearTimeout(timeoutRef);
  }, [gameFlowForward, countdown]);

  useEffect(() => {
    if (countdown === 0) {
      gameFlowForward();
    }
  }, [countdown, gameFlowForward]);

  return (
    <AspectRatioView>
      <div className={s.wrapper}>
        <h1 className={s.getReadyText}>{lang.getReady}</h1>
        <span className={s.countdown}>{countdown === 0 ? 1 : countdown}</span>
      </div>
    </AspectRatioView>
  );
};

export default GetReady;
