import { AspectRatioView, ButtonRect, HorizontalOrientationPrompt } from 'components';
import { useAppStateActions, useLanguageDirection } from 'appState';
import s from './Intro.module.scss';

type IntroProps = {
  gameTitle: string,
  description: string;
  buttonLabel: string;
  superPowerName: string;
  superPower: string;
};

const Intro: React.FC<IntroProps> = ({
  gameTitle,
  description,
  buttonLabel,
  superPowerName,
  superPower,
}) => {
  const { gameFlowForward } = useAppStateActions();
  const dir = useLanguageDirection();

  return (
    <>
      <HorizontalOrientationPrompt />
      <AspectRatioView>
        <div className={s.container} dir={dir}>
          <h3 className={s.gameTitle}>{gameTitle}</h3>
          <p className={s.description}>{description}</p>
          <div className={s.superPower}>
            <p>{superPower}</p>
            <p>{superPowerName}</p>
          </div>
          <ButtonRect onClick={gameFlowForward} text={buttonLabel} />
        </div>
      </AspectRatioView>
    </>
  );
};

export default Intro;
