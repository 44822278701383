import { AssessmentResults, Occupation, RiskBehavior } from 'types';

const sampleReportData: AssessmentResults = {
  date: '2021-02-13T02:07:25.990Z',

  cognitive: {
    memory: 0.32,
    adaptability: 0.5,
    attention: 0.75,
    problemSolving: 0.82,
    speed: 0.23,
    persistence: 1,
  },

  behavior: {
    proactivity: 0.75,
    riskBehavior: RiskBehavior.RiskAverse,
    growthMindset: 0.5,
  },

  emotionalIntelligence: {
    empathy: 0.2,
    selfAwareness: 0.3,
    selfManagement: 0.43,
    socialAwareness: 0.87,
    relationshipManagement: 0.9,
  },

  personality: {
    conscientiousness: 0.98,
    emotionalStability: 0.13,
    extraversion: 0.4,
    agreeableness: 0.23,
    openness: 0.76,
    facetScales: {
      assertiveness: 0.3,
      calm: 0.76,
      compassion: 0.4,
      creativeImagination: 0.4,
      energyLevel: 0.76,
      happy: 0.76,
      intellectualCuriosity: 0.4,
      organization: 0.4,
      productiveness: 0.76,
      respectfulness: 0.4,
      responsibility: 0.76,
      sociability: 0.76,
      trust: 0.4,
    },
  },

  occupationalInterests: {
    realistic: 0.43,
    investigative: 0.7,
    artistic: 0.88,
    social: 0.12,
    enterprising: 0.43,
    conventional: 0.12,
  },

  occupations: {
    bySkills: [
      'Agricultural Equipment Operators' as Occupation,
      'Ambulance Drivers and Attendants' as Occupation,
      'Veterinary Technicians' as Occupation,
      'Operations Research Analysts' as Occupation,
      'Park Naturalists' as Occupation,
      'Water Resource Specialists' as Occupation,
      'Payroll and Timekeeping Clerks' as Occupation,
      'Health Education Specialists' as Occupation,
      'Residential Advisors' as Occupation,
      'Psychiatric Technicians' as Occupation,
    ],
    byInterests: [
      'Range Managers' as Occupation,
      'Painters, Construction and Maintenance' as Occupation,
      'Ship Engineers' as Occupation,
      'Shoe and Leather Workers and Repairers' as Occupation,
      'Chemical Plant and System Operators' as Occupation,
      'Foresters' as Occupation,
      'Patternmakers, Wood' as Occupation,
      'Registered Nurses' as Occupation,
      'Water Resource Specialists' as Occupation,
      'Waiters and Waitresses' as Occupation,
    ],
  },

  extraGameResults: {
    artistic: 0.5,
    conventional: 0.5,
    enterprising: 0.5,
    investigative: 0.5,
    persistence: 0,
    realistic: 0.5,
    social: 0.5,
  },
};

export default sampleReportData;
