import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import s from './pages.module.scss';
import { useAppState, useTranslation } from '../appState';

const PrivacyPolicyPage: React.FC = () => {
  const lang = useTranslation('pages');
  const { privacyPolicy } = useAppState();
  return (
    <main className={s.wrapper}>
      <ReactMarkdown>
        {privacyPolicy}
      </ReactMarkdown>
      <Link className={s.homeLink} to="/">{lang.homepageBtn}</Link>
    </main>
  );
};

export default PrivacyPolicyPage;
