import { useAssessmentResults, useTranslation } from 'appState';
import { objectEntries } from 'utils';
import ChartItem from 'components/assessment/ChartItem/ChartItem';
import TextItem from 'AppFlow/Summary/GameResults/TextItem';

const useGameResults = () => {
  const results = useAssessmentResults();
  const lang = useTranslation('Assesment');

  if (!results) return null;

  const emotionsQuizResults = objectEntries(results.emotionalIntelligence)
    .filter((t): t is [keyof typeof results.emotionalIntelligence, number] => t[1] !== null)
    .sort(([, a], [, b]) => b - a)
    .map(([id, score]) => ({
      id,
      score,
      name: lang.cards.emotionalIntelligence[id].name,
      content: (
        <ChartItem
          key={id}
          name={lang.cards.emotionalIntelligence[id].name}
          value={score}
        />
      ),
    }));

  const cognitiveStrengths = objectEntries(results.cognitive)
    .filter((t): t is [keyof typeof results.cognitive, number] => t[1] !== null)
    .sort(([, a], [, b]) => b - a)
    .map(([id, score]) => ({
      id,
      score,
      name: lang.cards.cognitive[id].name,
      content: (
        <ChartItem
          key={id}
          name={lang.cards.cognitive[id].name}
          value={score}
        />
      ),
    }));

  const personality = {
    agreeableness: results.personality?.agreeableness,
    conscientiousness: results.personality?.conscientiousness,
    extraversion: results.personality?.extraversion,
    emotionalStability: results.personality?.emotionalStability,
    openness: results.personality?.openness,
  };
  const personalityQuizResults = objectEntries(personality)
    .filter((t): t is [keyof typeof personality, number] => t[1] !== null && t[1] !== undefined)
    .sort(([, a], [, b]) => b - a)
    .map(([id, score]) => ({
      id,
      score,
      name: lang.cards.personality[id].name,
      content: (
        <ChartItem
          key={id}
          name={lang.cards.personality[id].name}
          value={score}
        />
      ),
    }));

  const behaviorResults = objectEntries(results.behavior)
    .filter(
      (t): t is [
        Exclude<keyof typeof results.behavior, 'riskBehavior'>,
        number,
      ] => (
        t[0] !== 'riskBehavior'
      ),
    )
    .filter((t): t is [
      Exclude<keyof typeof results.behavior, 'riskBehavior'>,
      number,
    ] => t[1] !== null)
    .sort(([, a], [, b]) => b - a)
    .map(([id, score]) => ({
      id,
      score,
      name: lang.cards.behavior[id].name,
      content: (
        <ChartItem
          key={id}
          name={lang.cards.behavior[id].name}
          value={score}
        />
      ),
    }));

  const riskTakingType = (results.behavior.riskBehavior !== null)
    ? [{
      id: 'riskTaking',
      score: lang.cards.behavior.riskTaking.items[results.behavior.riskBehavior].name,
      name: 'Risk',
      content: (
        <TextItem
          key="riskTaking"
          name="Risk"
          value={lang.cards.behavior.riskTaking.items[results.behavior.riskBehavior].name}
        />
      ),
    }] : [];

  const persistence = (results.extraGameResults.persistence !== null)
    ? [{
      id: 'persistence',
      score: results.extraGameResults.persistence,
      name: lang.gameResultNames.extraGameResults.persistence,
      content: (
        <ChartItem
          key="persistence"
          name={lang.gameResultNames.extraGameResults.persistence}
          value={results.extraGameResults.persistence}
        />
      ),
    }] : [];

  const careersQuizResults = objectEntries(results.extraGameResults)
    .filter(
      (t): t is [
        Exclude<keyof typeof results.extraGameResults, 'persistence'>,
        number,
      ] => (
        t[0] !== 'persistence'
      ),
    )
    .filter((t): t is [
      Exclude<keyof typeof results.extraGameResults, 'persistence'>,
      number,
    ] => t[1] !== null)
    .sort(([, a], [, b]) => b - a)
    .map(([id, score]) => ({
      id,
      score,
      name: lang.gameResultNames.extraGameResults[id],
      content: (
        <ChartItem
          key={id}
          name={lang.gameResultNames.extraGameResults[id]}
          value={score}
        />
      ),
    }));

  const ungroupedResults = [
    ...cognitiveStrengths,
    ...persistence,
    ...behaviorResults,
    ...riskTakingType,
  ];

  return {
    careersQuizResults,
    emotionsQuizResults,
    personalityQuizResults,
    ungroupedResults,
  };
};

export default useGameResults;
