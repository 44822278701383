import classnames from 'classnames';

import s from './CareerInterestCard.module.scss';
import StrengthLabel from './StrengthLabel';
import { useLanguageDirection } from 'appState';

type Props = {
  name: string;
  percentage: number;
  description: string;
  examples: string;
  type: 'realistic'
  | 'investigative'
  | 'artistic'
  | 'social'
  | 'enterprising'
  | 'conventional';
};

const CareerInterestCard: React.FC<Props> = ({
  name,
  percentage,
  description,
  examples,
  type,
}) => {
  const dir = useLanguageDirection();
  
  return (
    <section className={s.card} dir={dir}>
      <h3 className={s.name}>{name}</h3>
      <span className={s.percentage}>{percentage}%</span>
      <span className={classnames(s.icon, s[type])} />
      <div className={s.strength}>
        <StrengthLabel percentage={percentage} />
      </div>
      <p className={s.description}>{description}</p>
      <p className={s.examples}>{examples}</p>
    </section>
  );
}

export default CareerInterestCard;
