import React from 'react';

import modalImg from 'assets/images/gameModalImg.svg';
import { ButtonRect } from 'components';
import { useLanguageDirection } from 'appState';

import { DefaultModalProps } from './types';
import s from '../GameModal.module.scss';

const Go: React.FC<DefaultModalProps> = ({ text, onClick }) => {
  const dir = useLanguageDirection();

  return (
    <>
      <div className={s.modal}>
        <div>
          <h6 dir={dir}>Your turn</h6>
          <p dir={dir}>{text}</p>
        </div>
        <img className={s.modalImg} src={modalImg} alt="Start practice trial illustration" />
      </div>
      <ButtonRect
        text="Go"
        onClick={onClick}
        isVisible={true}
        className={s.cornerBtn}
      />
    </>
  );
}

export default Go;
