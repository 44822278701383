import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { useAppStateActions, useLanguageDirection, useTranslation } from 'appState';

import s from './Welcome.module.scss';
import Animation from './Animation';

const Welcome: React.FC = () => {
  const lang = useTranslation('Welcome');
  const dir = useLanguageDirection();

  const { appFlowForward } = useAppStateActions();
  // const vp = useViewportSize();

  const [animationFired, setAnimationFired] = useState(false);
  const [animationStage, setAnimationStage] = useState(0);
  const [isConsent, setIsConset] = useState('false');
  useEffect(() => {
    if (!animationFired) return;
    const ref = setInterval(
      () => setAnimationStage(s => s + 1),
      300,
    );
    return () => clearInterval(ref);
  }, [animationFired, setAnimationStage]);

  useEffect(() => {
    const ref = setTimeout(
      () => setAnimationFired(true),
      1000,
    );
    return () => clearTimeout(ref);
  }, [setAnimationFired]);

  const readyToGo = animationStage >= 8;

  const handleConsent = () => (isConsent === 'true' ? setIsConset('false') : setIsConset('true'));

  return (
    <div
      className={s.wrapper}
      // style={{ height: `${vp.height}px` }}
      style={{ height: '100%' }}
      dir={dir}
    >
      <div className={s.text}>
        <p className={s['line-1']}>{lang.line1}</p>
        <h1 className={s['line-2']}>{lang.line2}</h1>
        <p className={s['line-3']}>{lang.line3}</p>
      </div>
      <div className={s.animation}>
        <Animation stage={animationStage} />
      </div>
      <div className={classnames(s.buttons, readyToGo && s.readyToGo)}>
        <Link
          to="/about"
          className={s.about}
        >
          {lang.about}
        </Link>
        <p className={s.dataUsage_text}>{lang.dataUsage}</p>
        <button
          type="button"
          className={s.startButton}
          onClick={() => appFlowForward()}
          disabled={isConsent === 'false'}
          data-cy='landingPageReadyButton'
        >
          {lang.start}
        </button>
        <div className={classnames(s.consent, readyToGo && s.readyToGo)}>
          <div className={s.consent_wrapper}>
            <input
              name="consent" type="checkbox" onChange={handleConsent}
              data-cy='landingPageConsentCheckbox'/>
            <div>
              <p className={s.consent_text}>{lang.consentCheckboxFirstPart}
                <Link
                  to="/privacy-policy"
                >
                  {lang.consentCheckboxSecondPart}
                </Link>
              </p>
              <p className={s.consent_text_two}>{lang.consentCheckboxThirdPart}</p>
            </div>
          </div>
        </div>
        <Link
          to="/privacy-policy"
          className={s.privacyPolicy}
        >
          {lang.privacyPolicy}
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
