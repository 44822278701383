import { useCallback, useMemo, useState } from 'react';

import { fillTranslationTextWithVariables } from 'hooks';
import { getDeviceInfo, objectEntries } from 'utils';
import { GameId } from 'types/enums';
import { QuizzResults } from 'types';
import { useAppStateActions, useTranslation } from 'appState';
import {
  GameFlow,
  Instructions,
  Intro,
  Quizz,
} from 'components/game';
import images from './images';
import { BigFiveResults } from './types';
import Feedback from '../../components/game/Feedback';
import { calcBigFiveGameResults } from '../../utils/scoresCalc';
import { BigFiveDto } from '../../utils/bigFive';

const BigFiveGameFlow: React.FC = () => {
  const { gameFlowForward, submitGameResults } = useAppStateActions();
  const lang = useTranslation('BigFive');
  const cardsLang = useTranslation('Assesment');
  const [gameResult, setGameResult] = useState<BigFiveDto>();

  const onComplete = useCallback((scores: QuizzResults) => {
    const results: BigFiveResults = {
      bigFiveTestScores: scores,
      bigFiveTestMetadata: {
        device: getDeviceInfo(),
      },
    };
    submitGameResults(GameId.BigFive, results);
    setGameResult(calcBigFiveGameResults(results.bigFiveTestScores));
    gameFlowForward();
  }, [submitGameResults, gameFlowForward]);

  const topTwoPersonalityTraits = useMemo(() => {
    if (!gameResult) {
      return undefined;
    }
    const relevantCareerPersonalityTypes = objectEntries(gameResult)
      .sort(([, a], [, b]) => b - a);
    return relevantCareerPersonalityTypes
      .slice(0, 2)
      .map(([id, _]) => (cardsLang.cards.personality[
        id as keyof typeof cardsLang.cards.personality
      ].labels[1]));
  }, [gameResult, cardsLang]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.title}
            description={lang.description}
            buttonLabel={lang.buttonLabels.intro}
            superPowerName={lang.superPowerName}
            superPower={lang.superPower}
          />
        ),
        () => (
          <Instructions
            name={lang.title}
            instructions={lang.instructions}
            nextButtonLabel={lang.buttonLabels.instructions}
          />
        ),
        () => (
          <Quizz
            questions={lang.questions}
            images={images}
            setSize={1}
            scoreLabels={lang.scoreLabels}
            onComplete={onComplete}
          />
        ),
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                {
                  firstTrait: topTwoPersonalityTraits?.[0] || '',
                  secondTrait: topTwoPersonalityTraits?.[1] || '',
                },
              )
            }
          />
        ),
      ]}
    </GameFlow>
  );
};

export default BigFiveGameFlow;
export type Results = BigFiveResults;
