import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';

import s from './TextSection.module.scss';
import { useLanguageDirection } from 'appState';

type Props = {
  center?: boolean;
  children: string;
};

const TextSection: React.FC<Props> = ({ children, center }) => {
  const dir = useLanguageDirection();

  return (
    <div className={classnames(s.text, center && s.center)} dir={dir}>
      <ReactMarkdown>
        {children}
      </ReactMarkdown>
    </div>
  );
}

export default TextSection;
