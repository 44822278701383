import { ButtonRect } from 'components';
import { ButtonType } from 'components/ButtonRect/ButtonRect.types';
import { useIsHorizontalOrientation } from 'hooks';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import s from './HorizontalOrientationPrompt.module.scss';
import phoneImg from './phone.png';
import { useTranslation } from 'appState';

const HorizontalOrientationPrompt: React.FC = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const isHorizontalOrientation = useIsHorizontalOrientation();
  const lang = useTranslation('HorizontalOrientationPrompt');

  useEffect(() => {
    setShowPrompt(!isHorizontalOrientation);
  }, [isHorizontalOrientation])

  if (!showPrompt) {
    return null;
  }

  return (
    <div className={s.prompt}>
      <button
        className={s.closeIcon}
        onClick={() => setShowPrompt(false)}
      />
      <img
        src={phoneImg}
        alt="mobile icon"
        className={s.phoneIcon}
      />
      <p className={s.Text}>
        {lang.TextInstruction}
      </p>
      <ButtonRect
        onClick={() => setShowDetails(true)}
        text={lang.howDoIDoThis}
        type={ButtonType.DEFAULT}
        className={s.ShowDetails}
      />
      {showDetails && (
        <div className={s.InstructionsWrapper}>
          <div className={s.Instructions}>
            <div className={s.ButtonArea}>
              <ButtonRect
                onClick={() => setShowDetails(false)}
                text=""
                type={ButtonType.CLOSE}
                className={s.Close}
              />
            </div>
            <ReactMarkdown>
              {lang.details}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default HorizontalOrientationPrompt;
