import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  InputAdornment,
  TextField,
} from '@mui/material';

import { useAppStateActions, useTranslation } from 'appState';
import { AspectRatioView, ButtonRect } from 'components';
import { isValidRsaId } from '../../utils/regex';
import s from './CollectExtraData.module.scss';

enum InputName {
  RSA_ID = 'rsaId',
}

const CollectExtraData: React.FC = () => {
  const { submitExtraDetails } = useAppStateActions();
  const [user, setUserData] = useState({
    rsaId: '',
  });
  const [inputsErrors, setInputErrors] = useState({
    rsaId: '',
  });
  const [isOpenModal, setOpenModal] = useState(false);
  const lang = useTranslation('CollectExtraData');
  const defaultError = lang.errorMsg;

  const inputs = useMemo(() => [
    {
      name: InputName.RSA_ID,
      errorMsg: lang.errorMsg,
      checkValidityFn: isValidRsaId,
      label: lang.rsaId,
      type: 'text',
    },
  ], [lang]);

  useEffect(() => {
    const closeModal = (): void => {
      setOpenModal(false);
    };

    window.addEventListener('popstate', closeModal);
    return () => window.removeEventListener('popstate', closeModal);
  }, []);

  const getErrorMessage = (inputName: string, value: string): string => {
    let errorMessage = '';
    if (!value.length) {
      return defaultError;
    }
    const currentInput = inputs.find((input) => input.name === inputName);
    if (currentInput && !currentInput.checkValidityFn(value)) {
      errorMessage = currentInput.errorMsg;
    }
    return errorMessage;
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (isOpenModal) {
      return;
    }
    const error = getErrorMessage(name, value);
    setInputErrors({ ...inputsErrors, [name]: error });
    if (value.length >= 13 && error) {
      setUserData({ ...user, [name]: '' });
      return;
    }
    setUserData({ ...user, [name]: value });
  };

  const isValidForm = (): boolean => {
    const {
      rsaId,
    } = user;
    return isValidRsaId(rsaId);
  };

  const handleSubmit = (): void => {
    const {
      rsaId,
    } = user;

    if (isValidForm()) {
      submitExtraDetails(rsaId);
    } else {
      setInputErrors({
        rsaId: getErrorMessage('rsaId', rsaId),
      });
    }
  };

  return (
    <AspectRatioView>
      <div className={s.container}>
        <div className={s.loginImg} />
        <form
          className={classNames(s.loginForm)}
          noValidate
          onSubmit={handleSubmit}
        >
          <div className={s.row}>
            <h4>{lang.heading}</h4>
            <div className={s.loginIcon} />
          </div>
          <div className={s.formInputs}>
            {inputs.map((element) => (
              <TextField
                key={element.name}
                name={element.name}
                label={element.label}
                color="primary"
                variant="outlined"
                onChange={handleChangeInput}
                required
                value={user[element.name]}
                helperText={inputsErrors[element.name]}
                error={!!inputsErrors[element.name]}
                className={classNames(s.field)}
                type={element.type}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className={classNames(s.icon, {
                        [s.visible]: !!user[element.name],
                        [s.success]: !inputsErrors[element.name],
                      })}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            ))}
            <p className={s.additionalRSAIDInfo}>{lang.additionalRSAIDInfo}</p>
          </div>
          <ButtonRect
            onClick={handleSubmit}
            text={lang.proceed}
            className={classNames(s.loginBtn, { [s.disabled]: !isValidForm() })}
          />
        </form>
      </div>
    </AspectRatioView>
  );
};

export default CollectExtraData;
