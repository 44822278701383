import { useEffect, useMemo } from 'react';

import {
  GameFlow,
  GetReady,
  Instructions,
  Intro,
  PlayGame,
} from 'components/game';
import { fillTranslationTextWithVariables } from 'hooks';
import { GameId } from 'types/enums';
import { useAppStateActions, useTranslation } from 'appState';

import Game from './Game';
import { useBretData, useBretGameActions } from './state';
import { BretResult } from './types';
import tutorialImages from './tutorialImages';
import playGameImg from './img/BRET-playGame.svg';
import Feedback from '../../components/game/Feedback';

const SampleGameFlow: React.FC = () => {
  const lang = useTranslation('BRET');
  const cardsLang = useTranslation('Assesment');
  const { startInstructionsTimer, stopInstructionsTimer, setStartGameTime } = useBretGameActions();
  const { finalResults } = useBretData();
  const { submitGameResults } = useAppStateActions();

  const riskTakingType = useMemo(() => {
    const riskBehaviour = finalResults?.bretMetadata.riskBehaviour;
    if (!riskBehaviour) {
      return '';
    }
    return cardsLang.cards.behavior.riskTaking.items[riskBehaviour].name;
  }, [finalResults, cardsLang.cards.behavior.riskTaking.items]);

  useEffect(setStartGameTime, [setStartGameTime]);

  useEffect(() => {
    if (finalResults) {
      submitGameResults(GameId.BRET, finalResults);
    }
  }, [finalResults, submitGameResults]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            description={lang.intro.description}
            buttonLabel={lang.intro.btnText}
            superPowerName={lang.intro.superPowerName}
            superPower={lang.intro.superPower}
            gameTitle={lang.gameName}
          />
        ),
        () => (
          <Instructions
            name={lang.gameName}
            instructions={lang.instructions.text}
            onStart={startInstructionsTimer}
            onEnd={stopInstructionsTimer}
            images={tutorialImages}
            texts={lang.tutorial}
            tutorial
          />
        ),
        () => <Game isPracticeMode />,
        () => (
          <PlayGame
            gameName={lang.gameName}
            imgSrc={playGameImg}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => <Game />,
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { riskBehaviour: riskTakingType },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default SampleGameFlow;
export type Results = BretResult;
