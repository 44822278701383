import React from 'react';
import classNames from 'classnames';
import modalImg from 'assets/images/gameModalImg.svg';
import { ButtonRect } from 'components';

import { SkipGameModalProps } from './types';
import s from '../GameModal.module.scss';
import { useLanguageDirection, useTranslation } from 'appState';

const SkipGame: React.FC<SkipGameModalProps> = ({ onConfirm, onCancel }) => {
  const lang = useTranslation('GameSkipper');
  const dir = useLanguageDirection();

  return (
    <>
      <div className={s.modal}>
        <div>
          <div className={classNames(s.feedbackIcon, s.success)} />
          <h6 dir={dir}>{lang.confirmationModalHeader}</h6>
          <p dir={dir}>{lang.confirmationModalContent}</p>
        </div>
        <img className={s.modalImg} src={modalImg} alt="." />
      </div>
      <ButtonRect
        text={lang.confirmationModalCancelButton}
        onClick={onCancel}
        isVisible={true}
        className={s.cancelBtn}
      />
      <ButtonRect
        text={lang.skipButton}
        onClick={onConfirm}
        isVisible={true}
        className={s.confirmBtn}
      />
    </>
  );
};

export default SkipGame;
