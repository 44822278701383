export const fillTranslationTextWithVariables = (
  text: string,
  variables: { [key: string]: string | number },
): string => {
  let newText = text;
  Object.keys(variables).forEach(key => {
    const valueToReplace = `\${${key}}`;
    newText = newText.replace(valueToReplace, variables[key].toString());
  });
  return newText;
};
