import ReactMarkdown from 'react-markdown';

import ChartItem from 'components/assessment/ChartItem/ChartItem';
import s from './PopupContent.module.scss';
import ChartItemPersonality from '../ChartItemPersonality/ChartItemPersonality';
import { useLanguageDirection } from 'appState';

type Props = {
  title?: string;
  text?: string;
  barColor?: string;
  items?: Array<{
    name: string;
    labels?: string[],
    description: string;
    descriptionLeft?: string[];
    descriptionRight?: string[];
    value: number;
  }>;
  type?: string;
};

const PopupContentCharts: React.FC<Props> = ({
  title,
  text,
  items,
  barColor,
  type,
}) => {
  const dir = useLanguageDirection();

  return (
    <section className={s.wrapper} dir={dir}>
      {title && type !== 'personality' && <h2 className={s.title}>{title}</h2>}
      {text && type !== 'personality' && (
        <div className={s.text}>
          <ReactMarkdown>
            {text}
          </ReactMarkdown>
        </div>
      )}
      {items?.length && (
        <div className={s.items}>
          {type !== 'personality'
            ? (
              items.map(({ name, value, description }, order) => (
                <ChartItem
                  key={name}
                  name={name}
                  value={value}
                  description={description}
                  order={order}
                  barColor={barColor}
                  type={type}
                />
              ))
            )
            : (
              items.map(({
                name,
                value,
                labels,
                description,
                descriptionLeft,
                descriptionRight,
              }, order) => (
                <ChartItemPersonality
                  key={name}
                  name={name}
                  labels={labels}
                  value={value}
                  description={description}
                  descriptionLeft={descriptionLeft}
                  descriptionRight={descriptionRight}
                  order={order}
                  barColor={barColor}
                  type={type}
                />
              ))
            )}
        </div>
      )}
    </section>
  );
}

export default PopupContentCharts;
