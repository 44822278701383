import { ReactNode, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import classnames from 'classnames';

import { ALTERNATIVE_GAME_FLOW } from 'config';

import {
  useAppState,
  useAppStateActions,
  useCurrentGameId,
  useDisplayGamesCompletedModal,
  useTranslation,
  useVirtualViewportSize,
} from 'appState';
import {
  AspectRatioView,
  LanguageSwitch,
  LogoutButton,
} from 'components';
import { GameId } from 'types/enums';

import ProgressMapItem from './ProgressMapItem';
import s from './ProgressMap.module.scss';
import GamesCompletedModal from './GamesCompletedModal/GamesCompletedModal';
import FullscreenButton from '../../components/FullscreenButton/FullscreenButton';

const PROGRESS_MAP_ANIMATION_DELAY = 1000;
const ANIMATION_TIME = 750;

const ProgressMap: React.FC = () => {

  const lang = useTranslation('ProgressMap');
  const gamesInfo = useTranslation('GamesInfo');
  const { appFlowForward, onGamesCompletedModalNext } = useAppStateActions();
  const games = useAppState(s => s.getCurrentUserGamesToPlay());
  const completedGames = useAppState(s => s.getCurrentUserCompletedGames());
  const completedGameIndices = completedGames.map(
    gameId => games.findIndex(id => id === gameId),
  );
  const currentGameId = useCurrentGameId();
  const gameIndex = games.findIndex((el) => el === currentGameId);
  const initialIndex = gameIndex < 0 ? games.length - 1 : gameIndex > 0 ? gameIndex - 1 : 0;
  const [currentGameIndex, setCurrentGameIndex] = useState(initialIndex);
  const isDesktopWidth = useVirtualViewportSize().width > 1000;
  const displayGamesCompletedModal = useDisplayGamesCompletedModal();

  useEffect(() => {
    setTimeout(() => {
      setCurrentGameIndex(gameIndex < 0 ? games.length : gameIndex);
    }, PROGRESS_MAP_ANIMATION_DELAY);
  }, [gameIndex, games]);

  useEffect(() => {
    if (currentGameIndex === games.length) {
      setTimeout(appFlowForward, 1.5 * PROGRESS_MAP_ANIMATION_DELAY);
    }
  }, [currentGameIndex, games.length, appFlowForward]);

  const renderIndicator = (
    onClickHandler: (e: (React.MouseEvent | React.KeyboardEvent)) => void,
    isSelected: boolean,
    index: number,
  ): ReactNode => {
    if (isSelected) {
      return (
        <li
          role="presentation"
          onClick={onClickHandler}
          className={classnames(s.indicator, s.selected, {
            [s.finished]: completedGameIndices.includes(index),
            [s.active]: index === currentGameIndex,
          })}
          aria-label={`Selected: Game ${gamesInfo[games[index]].gameName}`}
          title={`Selected: Game ${gamesInfo[games[index]].gameName}`}
        />
      );
    }
    return (
      <li
        className={classnames(s.indicator, {
          [s.finished]: completedGameIndices.includes(index),
          [s.active]: index === currentGameIndex,
        })}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
        tabIndex={0}
        title={`Game ${gamesInfo[games[index]].gameName}`}
        aria-label={`Game ${gamesInfo[games[index]].gameName}`}
      />
    );
  };

  return (
    <AspectRatioView background="#FFFFFF">
      <div className={s.container}>
        <div className={s.row}>
          <LanguageSwitch />
          <div>
            <h4>{lang.title}</h4>
            <p>
              {
                currentGameId
                  ? `${lang.upcoming} - ${gamesInfo[currentGameId].gameName}`
                  : lang.allCompleted
              }
            </p>
          </div>
          <div className={s.sideButtons}>
            <FullscreenButton text={lang.goFullscreen} />
            <LogoutButton text={lang.signOut} />
          </div>
        </div>
        <div className={s.carouselWrapper}>
          <Carousel
            showThumbs={false}
            showArrows={isDesktopWidth}
            swipeable
            centerSlidePercentage={100}
            centerMode
            renderIndicator={renderIndicator}
            selectedItem={currentGameIndex}
            width="100%"
            transitionTime={ANIMATION_TIME}
          >
            {games.map((game: GameId, index) => (
              <ProgressMapItem
                key={game}
                active={index === currentGameIndex}
                gameId={game}
                finished={completedGames.includes(game)}
                selectionMode={ALTERNATIVE_GAME_FLOW ? 'allUpcomingGames' : 'onlyNextGame'}
              />
            ))}
          </Carousel>
          <div className={s.background} />
          {
            displayGamesCompletedModal
            && <GamesCompletedModal onClick={onGamesCompletedModalNext} />
          }
        </div>
      </div>
    </AspectRatioView>
  );
};

export default ProgressMap;
