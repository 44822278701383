import classNames from 'classnames';
import { useEffect } from 'react';

import { useAppStateActions, useGameFlowStage, useTranslation } from 'appState';
import { GameId, GameMessageType } from 'types/enums';
import { ButtonRect, Toast } from 'components';

import { useCorsiGameActions, useCorsiGameData } from './state';
import { CorsiRoundStage } from './types';
import Fish from './Fish';
import s from './CorsiGame.module.scss';
import {
  FEEDBACK_ICON_DISPLAY_TIME,
  GAME_FLOW_FORWARD_DELAY_AFTER_FINISH_TRIALS,
  GO_BOX_DISPLAY_TIME,
} from './CorsiTimings';
import GameSkipper from 'components/game/GameSkipper/GameSkipper';

const CorsiGameArea: React.FC = () => {
  const lang = useTranslation('Corsi');
  const {
    isPracticeMode,
    corsiBlocks,
    sequence,
    stage,
    isSuccessRound,
    gameResult,
    isGameOver,
    isFinishedGameTrials,
  } = useCorsiGameData();
  const {
    roundStageForward,
    testTrialsForward,
    mainTrialsForward,
    startPracticeTrialsTimer,
  } = useCorsiGameActions();
  const { gameFlowForward, submitGameResults } = useAppStateActions();
  const gameFlowStage = useGameFlowStage();

  const blocks = corsiBlocks.map(({ id }) => (
    <Fish key={`fish-${id}`} id={id} order={sequence.indexOf(id) + 1} />));

  useEffect(() => {
    roundStageForward();
  }, [roundStageForward]);

  useEffect(() => {
    if (isPracticeMode) {
      startPracticeTrialsTimer();
    }
  }, [isPracticeMode, gameFlowStage, startPracticeTrialsTimer]);


  useEffect(() => {
    let time: number;
    if (isFinishedGameTrials && isPracticeMode) {
      time = window.setTimeout(gameFlowForward, GAME_FLOW_FORWARD_DELAY_AFTER_FINISH_TRIALS);
    }
    return () => clearTimeout(time);
  }, [gameFlowForward, isFinishedGameTrials, isPracticeMode]);

  useEffect((): void => {
    if (isGameOver) {
      submitGameResults(GameId.CORSI, gameResult);
      gameFlowForward();
    }
  }, [isGameOver, submitGameResults, gameResult, gameFlowForward]);

  return (
    <div className={classNames(s.gameArea,
      { [s.enableClick]: stage === CorsiRoundStage.USER_CHOICES })}
    >
      <GameSkipper className={s.skipButton} />
      {blocks}
      <ButtonRect
        text={lang.done}
        isVisible={stage === CorsiRoundStage.USER_CHOICES}
        className={s.corsiBtn}
        onClick={isPracticeMode ? testTrialsForward : mainTrialsForward}
      />
      <Toast
        duration={GO_BOX_DISPLAY_TIME}
        onEnd={roundStageForward}
        isVisible={stage === CorsiRoundStage.GO}
      >
        <p />
      </Toast>
      <Toast
        onEnd={roundStageForward}
        isVisible={stage === CorsiRoundStage.FEEDBACK}
        duration={FEEDBACK_ICON_DISPLAY_TIME}
      >
        {isPracticeMode ? (
          <Toast.Message
            messageType={isSuccessRound ? GameMessageType.SUCCESS : GameMessageType.INCORRECT}
          />
        ) : (<></>)}

      </Toast>
    </div>
  );
};

export default CorsiGameArea;
