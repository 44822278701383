import { TranslationsDto } from 'api/dto';

import { Translations } from './types';

import translationAmharic from 'translations/amharic.json';
import translationEnglish from 'translations/english.json';
import translationItalian from 'translations/italian.json';
import translationArabic from 'translations/arabic.json';

export * from './types';

export const baseTranslations: Translations = {
  'English': translationEnglish,
  'Amharic': translationAmharic,
  'Italian': translationItalian,
  'Arabic': translationArabic
}

export const createFullTranslations = (dto: TranslationsDto): Translations => {
  const occupationsEnglish = dto.find(t => t.language === 'English')?.occupation ?? {};
  const occupationsAmharic = dto.find(t => t.language === 'Amharic')?.occupation ?? {};
  const occupationsItalian = dto.find(t => t.language === 'Italian')?.occupation ?? {};
  const occupationsArabic = dto.find(t => t.language === 'Arabic')?.occupation ?? {};

  return {
    'English': {
      ...baseTranslations['English'],
      CareerOptionCard: {
        ...baseTranslations['English'].CareerOptionCard,
        occupations: occupationsEnglish
      }
    },
    'Amharic': {
      ...baseTranslations['Amharic'],
      CareerOptionCard: {
        ...baseTranslations['Amharic'].CareerOptionCard,
        occupations: occupationsAmharic
      }
    },
    'Italian': {
      ...baseTranslations['Italian'],
      CareerOptionCard: {
        ...baseTranslations['Italian'].CareerOptionCard,
        occupations: occupationsItalian
      }
    },
    'Arabic': {
      ...baseTranslations['Arabic'],
      CareerOptionCard: {
        ...baseTranslations['Arabic'].CareerOptionCard,
        occupations: occupationsArabic
      }
    },
  }
}