import { useEffect, useState } from 'react';
import { fillTranslationTextWithVariables } from 'hooks';

import { Occupation } from 'types';
import {
  Button,
  CardCarousel,
  CareerInterestCard,
  CareerOptionCard,
  Heading,
  Layout,
  LearningCenter,
  LearningCenterGroupCard,
  Overview,
  PdfButton,
  SkillStrengthCard,
  SubHeading,
  TextSection,
} from 'components/assessment';
import { useAppState, useAssessmentVisibility, useLanguage, useTranslation } from 'appState';
import { getLearningCenterSectionName } from 'utils';
import useCareerTracker from './useCareerTracker';

import { Section } from './enums';
import useLinks from './useLinks';
import { useAssessmentStateActions } from './state';
import { OccupationalInterest, SkillStrength } from './types';

type Props = {
  username: string;
  testDate: string;
  onDownloadPdf: () => Promise<unknown>;
  onEmailPdf: () => Promise<unknown>;
  skillStrengths: SkillStrength[];
  occupationalInterests: OccupationalInterest[];
  occupations: {
    byInterests: Occupation[],
    bySkills: Occupation[],
  },
  onSectionSelected: (section: Section) => void;
};

const Career: React.FC<Props> = ({
  username,
  testDate,
  skillStrengths,
  occupationalInterests,
  occupations,
  onDownloadPdf,
  onSectionSelected,
}) => {
  const lang = useTranslation('Assesment');
  const currentLanguage = useLanguage();
  const {
    career: careerLinks,
    careerOptions: careerOptionsLinks,
  } = useLinks();
  const translatedLinks = useTranslation('Assesment').career;
  const visibility = useAssessmentVisibility();
  const isAnyCareerOptionVisible = visibility.feedbackVisibilityOptions.careerOptionsInterests
    || visibility.feedbackVisibilityOptions.careerOptionsStrengths;
  const isCareerOptionsHeadingVisible = occupations.byInterests.length > 0
    || occupations.bySkills.length > 0;
  const { incrementInteraction } = useAssessmentStateActions();

  const getLearningCenterCardByName = (name: string): number => {
    const index = careerLinks
      .findIndex(item => getLearningCenterSectionName(item.heading) === name);
    if (index !== -1) {
      return index;
    }
    return 0;
  };

  const { learningCornerRef, mainFeedbackRef } = useCareerTracker();
  const { selectedAssessmentLearningSection } = useAppState();
  const [chosenLearningCenterItem, setChosenLearningCenterItem] = useState(0);
  useEffect(() => {
    if (!selectedAssessmentLearningSection) {
      return;
    }
    const itemToDisplay = getLearningCenterCardByName(selectedAssessmentLearningSection);
    setChosenLearningCenterItem(itemToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssessmentLearningSection]);

  return (
    <>
      <Layout.Content.Section id={Section.CareerOverview}>
        <Overview
          username={username}
          testDate={testDate}
          // TODO: fix for Arabic
          heading={fillTranslationTextWithVariables(lang.careerOverview.heading, { username })}
          text={lang.careerOverview.text}
          buttonLabel={lang.goToCareerInterests}
          showButtonIf={
            visibility.feedbackVisibilityOptions.careerInterests
            && occupationalInterests.length > 0
          }
          onButtonClick={() => {
            onSectionSelected(Section.CareerInterests);
            incrementInteraction('mainFeedbackInteractions', 'goToCareerInterests');
          }}
          pdfHeading={lang.pdf.heading}
          pdfDescription={lang.pdf.description}
        >
          {
            visibility.feedbackVisibilityOptions.pdfDownload
            && (
              <PdfButton
                action={onDownloadPdf}
                label={lang.pdf.download}
                labelError={lang.pdf.error}
                icon="pdf"
              />
            )
          }
        </Overview>
      </Layout.Content.Section>
      <Layout.Content.ObservableSection
        ref={mainFeedbackRef}
        id="main-feedback"
      >
        <Layout.Content.Section
          id={Section.CareerSkillStrenghts}
          showIf={visibility.feedbackVisibilityOptions.careerStrengths && skillStrengths.length > 0}
        >
          <Heading>{lang.skillStrengthsHeading}</Heading>
          <CardCarousel>
            {
              skillStrengths.map(({
                id,
                category,
                name,
                description,
                type,
                popupContent,
              }) => (
                <SkillStrengthCard
                  key={id}
                  title={category}
                  subtitle={name || ''}
                  description={description}
                  type={type}
                  popupContent={popupContent}
                  interactionType="mainFeedbackInteractions"
                />
              ))
            }
          </CardCarousel>
        </Layout.Content.Section>
        <Layout.Content.Section
          id={Section.CareerInterests}
          showIf={
            visibility.feedbackVisibilityOptions.careerInterests
            && occupationalInterests.length > 0
          }
        >
          <Heading>{lang.occupationalInterestsHeading}</Heading>
          <TextSection center>{lang.occupationalInterestsText}</TextSection>
          <CardCarousel>
            {
              occupationalInterests.map(({
                id,
                name,
                percentage,
                description,
                examples,
              }) => (
                <CareerInterestCard
                  key={id}
                  type={id}
                  name={name}
                  percentage={percentage}
                  description={description}
                  examples={examples}
                />
              ))
            }
          </CardCarousel>
        </Layout.Content.Section>
        <Layout.Content.Section
          showIf={
            visibility.feedbackVisibilityOptions.careerInterests
            && occupationalInterests.length > 0
          }
        >
          <TextSection center>
            {lang.upskillingResourcesText}
          </TextSection>
          <Button
            style={{ display: 'block', margin: '-24px auto 0 auto' }}
            onClick={() => {
              onSectionSelected(Section.CareerLearningCenter);
              incrementInteraction('mainFeedbackInteractions', 'gotToLearningCenter');
            }}
          >
            {lang.goToLearningCenter}
          </Button>
        </Layout.Content.Section>
        <Layout.Content.Section
          id={Section.CareerOptions}
          showIf={isAnyCareerOptionVisible && isCareerOptionsHeadingVisible}
        >
          <Heading>{lang.careerOptionsHeading}</Heading>
        </Layout.Content.Section>
        <Layout.Content.Section
          showIf={
            isAnyCareerOptionVisible
            && (occupations.byInterests.length + occupations.bySkills.length) > 0
          }
        >
          <TextSection>
            {fillTranslationTextWithVariables(lang.careerOptionsText, { username })}
          </TextSection>
        </Layout.Content.Section>
        <Layout.Content.Section
          showIf={
            visibility.feedbackVisibilityOptions.careerOptionsInterests
            && occupations.byInterests.length > 0
          }
        >
          <Heading secondary>{lang.careersByCareerInterests}</Heading>
          <CardCarousel>
            {
              occupations.byInterests.map(occupation => (
                <CareerOptionCard
                  occupation={occupation}
                  basedOn="careerInterest"
                  key={occupation}
                  language={currentLanguage}
                />
              ))
            }
          </CardCarousel>
        </Layout.Content.Section>
        <Layout.Content.Section
          showIf={
            visibility.feedbackVisibilityOptions.careerOptionsStrengths
            && occupations.bySkills.length > 0
          }
        >
          <Heading secondary>{lang.careersBySkillStrengths}</Heading>
          <CardCarousel>
            {
              occupations.bySkills.map(occupation => (
                <CareerOptionCard
                  occupation={occupation}
                  basedOn="skillStrengths"
                  key={occupation}
                  language={currentLanguage}
                />
              ))
            }
          </CardCarousel>
          <LearningCenter
            resources={[
              {
                items: careerOptionsLinks,
              },
            ]}
            interactionType="mainFeedbackInteractions"
          />
        </Layout.Content.Section>
      </Layout.Content.ObservableSection>
      <Layout.Content.ObservableSection
        id={Section.CareerLearningCenter}
        showIf={visibility.feedbackVisibilityOptions.careerLearningCenter}
        ref={learningCornerRef}
      >
        <Heading>{lang.learningCenter}</Heading>
        <SubHeading>{lang.learningCenterDescription}</SubHeading>
        <CardCarousel
          chosenItem={chosenLearningCenterItem}
          onChosenItemChange={setChosenLearningCenterItem}
        >
          {translatedLinks.map(group => (
            <LearningCenterGroupCard key={group.heading} resource={group} />
          ))}
        </CardCarousel>
      </Layout.Content.ObservableSection>
    </>
  );
};

export default Career;
