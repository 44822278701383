import classnames from 'classnames';

import { ButtonRect } from 'components';
import { useBretData, useBretGameActions } from './state';
import { BretRoundStage } from './types';

import s from './BRET.module.scss';
import { useTranslation } from 'appState';

const BretFeedback: React.FC = () => {
  const { currentStage, didBombExplode } = useBretData();
  const { gameStageForward } = useBretGameActions();
  const lang = useTranslation('BRET');

  return (
    <div className={classnames(s.feedbackBox, {
      [s.visible]: currentStage === BretRoundStage.FEEDBACK,
    })}
    >
      <div className={classnames(s.feedbackImg, {
        [s.coin]: !didBombExplode,
        [s.explode]: didBombExplode,
      })}  data-cy='explode'
      />
      <ButtonRect onClick={gameStageForward} text={lang.continue} />
    </div>
  );
};
export default BretFeedback;
