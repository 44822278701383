/* eslint-disable max-len */

import { Occupation } from 'types';

// TODO: Retrieve from api
const links = {
  'General and Operations Managers': 'https://www.bls.gov/ooh/management/top-executives.htm#tab-2',
  'Marketing Managers': 'https://www.bls.gov/ooh/management/advertising-promotions-and-marketing-managers.htm#tab-2',
  'Sales Managers': 'https://www.bls.gov/ooh/management/sales-managers.htm#tab-2',
  'Industrial Production Managers': 'https://www.bls.gov/ooh/management/industrial-production-managers.htm#tab-2',
  'Human Resources Managers': 'https://www.bls.gov/ooh/management/human-resources-managers.htm#tab-2',
  'Construction Managers': 'https://www.bls.gov/ooh/management/construction-managers.htm',
  'Education and Childcare Administrators, Preschool and Daycare': 'https://www.bls.gov/ooh/management/preschool-and-childcare-center-directors.htm#tab-2',
  'Food Service Managers': 'https://www.bls.gov/ooh/management/food-service-managers.htm#tab-2',
  'Water Resource Specialists': 'https://www.mynextmove.org/profile/summary/11-9121.02',
  'Postmasters and Mail Superintendents': 'https://www.bls.gov/oes/current/oes119131.htm',
  'Property, Real Estate, and Community Association Managers': 'https://www.bls.gov/ooh/management/property-real-estate-and-community-association-managers.htm#tab-2',
  'Social and Community Service Managers': 'https://www.bls.gov/ooh/management/social-and-community-service-managers.htm#tab-2',
  'Buyers and Purchasing Agents': 'https://www.bls.gov/ooh/business-and-financial/purchasing-managers-buyers-and-purchasing-agents.htm#tab-2',
  'Environmental Compliance Inspectors': 'https://www.onetonline.org/link/summary/13-1041.01',
  'Human Resources Specialists': 'https://www.bls.gov/ooh/business-and-financial/human-resources-specialists.htm#tab-2',
  'Farm Labor Contractors': 'https://www.onetonline.org/link/summary/13-1074.00#:~:text=Recruit%20and%20hire%20seasonal%20or,and%20provide%20meals%20for%20workers.',
  'Meeting, Convention, and Event Planners': 'https://www.bls.gov/ooh/business-and-financial/meeting-convention-and-event-planners.htm#tab-2',
  'Sustainability Specialists': 'https://www.onetonline.org/link/summary/13-1199.05',
  'Online Merchants': 'https://www.onetonline.org/link/summary/13-1199.06',
  'Personal Financial Advisors': 'https://www.bls.gov/ooh/business-and-financial/personal-financial-advisors.htm#tab-2',
  'Loan Officers': 'https://www.bls.gov/ooh/business-and-financial/loan-officers.htm#tab-2',
  'Actuaries': 'https://www.bls.gov/ooh/math/actuaries.htm#tab-2',
  'Operations Research Analysts': 'https://www.bls.gov/ooh/math/operations-research-analysts.htm#tab-2',
  'Statisticians': 'https://www.bls.gov/ooh/math/mathematicians-and-statisticians.htm#tab-2',
  'Architects': 'https://www.bls.gov/ooh/architecture-and-engineering/architects.htm#tab-2',
  'Surveyors': 'https://www.bls.gov/ooh/architecture-and-engineering/surveyors.htm#tab-2',
  'Agricultural Engineers': 'https://www.bls.gov/ooh/architecture-and-engineering/agricultural-engineers.htm#tab-2',
  'Chemical Engineers': 'https://www.bls.gov/ooh/architecture-and-engineering/chemical-engineers.htm#tab-2',
  'Civil Engineers': 'https://www.bls.gov/ooh/architecture-and-engineering/civil-engineers.htm#tab-2',
  'Transportation Engineers': 'https://www.onetonline.org/link/summary/17-2051.01#:~:text=Develop%20plans%20for%20surface%20transportation,or%20estimates%20for%20transportation%20facilities.',
  'Electrical Engineers': 'https://www.bls.gov/ooh/architecture-and-engineering/electrical-and-electronics-engineers.htm#tab-2',
  'Mechanical Engineers': 'https://www.bls.gov/ooh/architecture-and-engineering/mechanical-engineers.htm#tab-2',
  'Automotive Engineers': 'https://www.onetonline.org/link/summary/17-2141.02',
  'Wind Energy Engineers': 'https://www.onetonline.org/link/summary/17-2199.10',
  'Solar Energy Systems Engineers': 'https://www.onetonline.org/link/summary/17-2199.11',
  'Civil Engineering Technicians': 'https://www.onetonline.org/link/summary/17-3022.00',
  'Environmental Engineering Technicians': 'https://www.onetonline.org/link/summary/17-3025.00',
  'Industrial Engineering Technicians': 'https://www.bls.gov/ooh/architecture-and-engineering/industrial-engineering-technicians.htm#tab-2',
  'Automotive Engineering Technicians': 'https://www.onetonline.org/link/summary/17-3027.01',
  'Animal Scientists': 'https://www.bls.gov/oes/current/oes191011.htm',
  'Food Scientists and Technologists': 'https://www.bls.gov/ooh/life-physical-and-social-science/agricultural-and-food-scientists.htm#tab-2',
  'Soil and Plant Scientists': 'https://www.onetonline.org/link/summary/19-1013.00',
  'Range Managers': 'https://www.onetonline.org/link/summary/19-1031.02',
  'Park Naturalists': 'https://www.onetonline.org/link/summary/19-1031.03',
  'Foresters': 'https://www.bls.gov/ooh/life-physical-and-social-science/conservation-scientists.htm#tab-2',
  'Epidemiologists': 'https://www.bls.gov/ooh/life-physical-and-social-science/epidemiologists.htm#tab-2',
  'Medical Scientists': 'https://www.bls.gov/ooh/life-physical-and-social-science/medical-scientists.htm#tab-2',
  'Environmental Scientists and Specialists': 'https://www.bls.gov/ooh/life-physical-and-social-science/environmental-scientists-and-specialists.htm#tab-2',
  'Geographers': 'https://www.bls.gov/ooh/life-physical-and-social-science/geographers.htm#tab-2',
  'Transportation Planners': 'https://www.onetonline.org/link/summary/19-3099.01',
  'Chemical Technicians': 'https://www.bls.gov/ooh/life-physical-and-social-science/chemical-technicians.htm#tab-2',
  'Social Science Research Assistants': 'https://www.onetonline.org/link/summary/19-4061.00',
  'Quality Control Analysts': 'https://www.bls.gov/ooh/production/quality-control-inspectors.htm#tab-2',
  'Educational, Guidance, and Career Counselors': 'https://www.bls.gov/ooh/community-and-social-service/school-and-career-counselors.htm#tab-2',
  'Child, Family, and School Social Workers': 'https://www.onetonline.org/link/summary/21-1021.00',
  'Healthcare Social Workers': 'https://www.onetonline.org/link/summary/21-1022.00',
  'Health Education Specialists': 'https://www.bls.gov/oes/current/oes211091.htm',
  'Social and Human Service Assistants': 'https://www.bls.gov/ooh/community-and-social-service/social-and-human-service-assistants.htm#tab-2',
  'Community Health Workers': 'https://www.bls.gov/ooh/community-and-social-service/health-educators.htm#tab-2',
  'Lawyers': 'https://www.bls.gov/ooh/legal/lawyers.htm#tab-2',
  'Paralegals and Legal Assistants': 'https://www.bls.gov/ooh/legal/paralegals-and-legal-assistants.htm#tab-2',
  'Elementary School Teachers': 'https://www.bls.gov/ooh/education-training-and-library/kindergarten-and-elementary-school-teachers.htm#tab-2',
  'Special Education Teachers': 'https://www.bls.gov/ooh/education-training-and-library/special-education-teachers.htm#tab-2',
  'Adapted Physical Education Specialists': 'https://www.onetonline.org/link/summary/25-2059.01',
  'Adult Educators': 'https://www.onetonline.org/link/summary/25-3011.00',
  'Archivists': 'https://www.bls.gov/ooh/education-training-and-library/curators-museum-technicians-and-conservators.htm#tab-2',
  'Museum Technicians and Conservators': 'https://www.bls.gov/ooh/education-training-and-library/curators-museum-technicians-and-conservators.htm#tab-2',
  'Library Technicians': 'https://www.bls.gov/ooh/education-training-and-library/library-technicians-and-assistants.htm#tab-2',
  'Craft Artists': 'https://www.bls.gov/ooh/arts-and-design/craft-and-fine-artists.htm#tab-2',
  'Commercial and Industrial Designers': 'https://www.bls.gov/ooh/arts-and-design/industrial-designers.htm#tab-2',
  'Fashion Designers': 'https://www.bls.gov/ooh/arts-and-design/fashion-designers.htm#tab-2',
  'Floral Designers': 'https://www.bls.gov/ooh/arts-and-design/floral-designers.htm#tab-2',
  'Graphic Designers': 'https://www.bls.gov/ooh/arts-and-design/graphic-designers.htm#tab-2',
  'Merchandise Displayers': 'https://www.onetonline.org/link/summary/27-1026.00',
  'Set and Exhibit Designers': 'https://www.onetonline.org/link/summary/27-1027.00',
  'Actors': 'https://www.bls.gov/ooh/entertainment-and-sports/actors.htm#tab-2',
  'Poets, Lyricists and Creative Writers': 'https://www.bls.gov/ooh/media-and-communication/writers-and-authors.htm#tab-2',
  'Interpreters and Translators': 'https://www.bls.gov/ooh/media-and-communication/interpreters-and-translators.htm#tab-2',
  'Audio and Video Technicians': 'https://www.bls.gov/oes/2017/may/oes274011.htm',
  'Photographers': 'https://www.bls.gov/ooh/media-and-communication/photographers.htm#tab-2',
  'Camera Operators, Television, Video, and Film': 'https://www.bls.gov/ooh/media-and-communication/film-and-video-editors-and-camera-operators.htm#tab-2',
  'Film and Video Editors': 'https://www.bls.gov/ooh/media-and-communication/film-and-video-editors-and-camera-operators.htm#tab-2',
  'Dentists': 'https://www.bls.gov/ooh/healthcare/dentists.htm#tab-2',
  'Physician Assistants': 'https://www.bls.gov/ooh/healthcare/physician-assistants.htm#tab-2',
  'Occupational Therapists': 'https://www.bls.gov/ooh/healthcare/occupational-therapists.htm#tab-2',
  'Physical Therapists': 'https://www.bls.gov/ooh/healthcare/physical-therapists.htm#tab-2',
  'Veterinarians': 'https://www.bls.gov/ooh/healthcare/veterinarians.htm#tab-2',
  'Registered Nurses': 'https://www.bls.gov/ooh/healthcare/registered-nurses.htm#tab-2',
  'Nurse Practitioners': 'https://www.bls.gov/ooh/healthcare/nurse-anesthetists-nurse-midwives-and-nurse-practitioners.htm#tab-2',
  'Medical and Clinical Laboratory Technicians': 'https://www.bls.gov/ooh/healthcare/clinical-laboratory-technologists-and-technicians.htm#tab-2',
  'Dietetic Technicians': 'https://www.onetonline.org/link/summary/29-2051.00',
  'Pharmacy Technicians': 'https://www.bls.gov/ooh/healthcare/pharmacy-technicians.htm#tab-2',
  'Psychiatric Technicians': 'https://www.bls.gov/ooh/healthcare/psychiatric-technicians-and-aides.htm#tab-2',
  'Surgical Technologists': 'https://www.bls.gov/ooh/healthcare/surgical-technologists.htm#tab-2',
  'Veterinary Technicians': 'https://www.bls.gov/ooh/healthcare/veterinary-technologists-and-technicians.htm#tab-2',
  'Licensed Practical and Licensed Vocational Nurses': 'https://www.bls.gov/ooh/healthcare/licensed-practical-and-licensed-vocational-nurses.htm#tab-2',
  'Midwives': 'https://www.onetonline.org/link/summary/29-9099.01',
  'Occupational Therapy Assistants': 'https://www.onetonline.org/link/summary/31-2011.00',
  'Physical Therapist Aides': 'https://www.bls.gov/ooh/healthcare/physical-therapist-assistants-and-aides.htm#tab-2',
  'Massage Therapists': 'https://www.bls.gov/ooh/healthcare/massage-therapists.htm#tab-2',
  'Dental Assistants': 'https://www.bls.gov/ooh/healthcare/dental-assistants.htm#tab-2',
  'Medical Assistants': 'https://www.bls.gov/ooh/healthcare/medical-assistants.htm#tab-2',
  'Medical Equipment Preparers': 'https://www.bls.gov/oes/2018/may/oes319093.htm',
  'Pharmacy Aides': 'https://www.onetonline.org/link/summary/31-9095.00',
  'Veterinary Assistants and Laboratory Animal Caretakers': 'https://www.bls.gov/ooh/healthcare/veterinary-assistants-and-laboratory-animal-caretakers.htm#tab-2',
  'Speech-Language Pathology Assistants': 'https://www.onetonline.org/link/summary/31-9099.01',
  'Forest Fire Inspectors and Prevention Specialists': 'https://www.onetonline.org/link/summary/33-2022.00',
  'Fish and Game Wardens': 'https://www.onetonline.org/link/summary/33-3031.00',
  'Transit and Railroad Police': 'https://www.onetonline.org/link/summary/33-3052.00',
  'Animal Control Workers': 'https://www.onetonline.org/link/summary/33-9011.00',
  'Chefs and Head Cooks': 'https://www.bls.gov/ooh/food-preparation-and-serving/chefs-and-head-cooks.htm#tab-2',
  'Cooks, Private Household': 'https://www.bls.gov/ooh/food-preparation-and-serving/cooks.htm#tab-2',
  'Food Preparation Workers': 'https://www.bls.gov/ooh/food-preparation-and-serving/food-preparation-workers.htm#tab-2',
  'Waiters and Waitresses': 'https://www.bls.gov/ooh/food-preparation-and-serving/waiters-and-waitresses.htm#tab-2',
  'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop': 'https://www.bls.gov/ooh/food-preparation-and-serving/food-and-beverage-serving-and-related-workers.htm#tab-2',
  'Landscaping and Groundskeeping Workers': 'https://www.bls.gov/ooh/building-and-grounds-cleaning/grounds-maintenance-workers.htm#tab-2',
  'Animal Caretakers': 'https://www.bls.gov/ooh/personal-care-and-service/animal-care-and-service-workers.htm#tab-2',
  'Barbers': 'https://www.bls.gov/ooh/personal-care-and-service/barbers-hairstylists-and-cosmetologists.htm#tab-2',
  'Hairdressers, Hairstylists, and Cosmetologists': 'https://www.bls.gov/ooh/personal-care-and-service/barbers-hairstylists-and-cosmetologists.htm#tab-3',
  'Makeup Artists, Theatrical and Performance': 'https://www.onetonline.org/link/summary/39-5091.00',
  'Manicurists and Pedicurists': 'https://www.bls.gov/ooh/personal-care-and-service/manicurists-and-pedicurists.htm#tab-2',
  'Skincare Specialists': 'https://www.bls.gov/ooh/personal-care-and-service/skincare-specialists.htm#tab-2',
  'Tour Guides and Escorts': 'https://www.onetonline.org/link/summary/39-7011.00',
  'Childcare Workers': 'https://www.bls.gov/ooh/personal-care-and-service/childcare-workers.htm#tab-2',
  'Residential Advisors': 'https://www.onetonline.org/link/summary/39-9041.00',
  'Cashiers': 'https://www.bls.gov/ooh/sales/cashiers.htm#tab-2',
  'Retail Salespersons': 'https://www.bls.gov/ooh/sales/retail-sales-workers.htm#tab-2',
  'Travel Agents': 'https://www.bls.gov/ooh/sales/travel-agents.htm#tab-2',
  'Sales Representatives': 'https://www.onetonline.org/link/summary/41-4012.00',
  'Demonstrators and Product Promoters': 'https://www.onetonline.org/link/summary/41-9011.00',
  'Real Estate Sales Agents': 'https://www.bls.gov/ooh/sales/real-estate-brokers-and-sales-agents.htm#tab-2',
  'Payroll and Timekeeping Clerks': 'https://www.bls.gov/ooh/office-and-administrative-support/financial-clerks.htm#tab-2',
  'Procurement Clerks': 'https://www.bls.gov/ooh/office-and-administrative-support/financial-clerks.htm#tab-3',
  'Tellers': 'https://www.bls.gov/ooh/office-and-administrative-support/tellers.htm#tab-2',
  'Customer Service Representatives': 'https://www.bls.gov/ooh/office-and-administrative-support/customer-service-representatives.htm#tab-2',
  'Library Assistants, Clerical': 'https://www.bls.gov/ooh/education-training-and-library/library-technicians-and-assistants.htm#tab-2',
  'Human Resources Assistants': 'https://www.bls.gov/ooh/business-and-financial/human-resources-specialists.htm#tab-2',
  'Receptionists and Information Clerks': 'https://www.bls.gov/ooh/office-and-administrative-support/receptionists.htm#tab-2',
  'Cargo and Freight Agents': 'https://www.onetonline.org/link/summary/43-5011.00',
  'Postal Service Mail Carriers': 'https://www.bls.gov/ooh/office-and-administrative-support/postal-service-workers.htm#tab-2',
  'Secretaries and Administrative Assistants': 'https://www.bls.gov/ooh/office-and-administrative-support/secretaries-and-administrative-assistants.htm#tab-2',
  'Data Entry Keyers': 'https://www.onetonline.org/link/summary/43-9021.00',
  'Word Processors and Typists': 'https://www.onetonline.org/link/summary/43-9022.00',
  'Statistical Assistants': 'https://www.onetonline.org/link/summary/43-9111.00',
  'Animal Breeders': 'https://www.bls.gov/ooh/personal-care-and-service/animal-care-and-service-workers.htm#tab-2',
  'Agricultural Equipment Operators': 'https://www.bls.gov/ooh/farming-fishing-and-forestry/agricultural-workers.htm#tab-2',
  'Forest and Conservation Workers': 'https://www.bls.gov/ooh/farming-fishing-and-forestry/forest-and-conservation-workers.htm#tab-2',
  'Logging Equipment Operators': 'https://www.bls.gov/ooh/farming-fishing-and-forestry/logging-workers.htm#tab-2',
  'First-Line Supervisors of Construction Trades and Extraction Workers': 'https://www.onetonline.org/link/summary/47-1011.00',
  'Boilermakers': 'https://www.bls.gov/ooh/construction-and-extraction/boilermakers.htm#tab-2',
  'Stonemasons': 'https://www.bls.gov/ooh/construction-and-extraction/brickmasons-blockmasons-and-stonemasons.htm#tab-2',
  'Tile and Stone Setters': 'https://www.bls.gov/ooh/construction-and-extraction/tile-and-marble-setters.htm#tab-2',
  'Cement Masons and Concrete Finishers': 'https://www.bls.gov/ooh/construction-and-extraction/brickmasons-blockmasons-and-stonemasons.htm#tab-2',
  'Operating Engineers and Other Construction Equipment Operators': 'https://www.bls.gov/ooh/construction-and-extraction/construction-equipment-operators.htm#tab-2',
  'Drywall and Ceiling Tile Installers': 'https://www.bls.gov/ooh/construction-and-extraction/drywall-and-ceiling-tile-installers-and-tapers.htm#tab-2',
  'Electricians': 'https://www.bls.gov/ooh/construction-and-extraction/electricians.htm#tab-2',
  'Glaziers': 'https://www.bls.gov/ooh/construction-and-extraction/glaziers.htm#tab-2',
  'Painters, Construction and Maintenance': 'https://www.bls.gov/ooh/construction-and-extraction/painters-construction-and-maintenance.htm#tab-2',
  'Roofers': 'https://www.bls.gov/ooh/construction-and-extraction/roofers.htm#tab-2',
  'Rail-Track Laying and Maintenance Equipment Operators': 'https://www.onetonline.org/link/summary/47-4061.00',
  'Computer, Automated Teller, and Office Machine Repairers': 'https://www.bls.gov/ooh/about/data-for-occupations-not-covered-in-detail.htm',
  'Electric Motor, Power Tool, and Related Repairers': 'https://www.bls.gov/ooh/installation-maintenance-and-repair/electrical-and-electronics-installers-and-repairers.htm#tab-2',
  'Security and Fire Alarm Systems Installers': 'https://www.onetonline.org/link/summary/49-2098.00',
  'Automotive Body and Related Repairers': 'https://www.bls.gov/ooh/installation-maintenance-and-repair/automotive-body-and-glass-repairers.htm#tab-2',
  'Maintenance and Repair Workers, General': 'https://www.bls.gov/ooh/installation-maintenance-and-repair/general-maintenance-and-repair-workers.htm#tab-2',
  'Commercial Divers': 'https://www.onetonline.org/link/summary/49-9092.00',
  'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers': 'https://www.bls.gov/ooh/production/assemblers-and-fabricators.htm#tab-2',
  'Engine and Other Machine Assemblers': 'https://www.bls.gov/ooh/production/assemblers-and-fabricators.htm#tab-3',
  'Bakers': 'https://www.bls.gov/ooh/production/bakers.htm#tab-2',
  'Butchers and Meat Cutters': 'https://www.bls.gov/ooh/production/butchers-and-meat-cutters.htm#tab-2',
  'Machinists': 'https://www.bls.gov/ooh/production/machinists-and-tool-and-die-makers.htm#tab-2',
  'Tool and Die Makers': 'https://www.bls.gov/ooh/production/machinists-and-tool-and-die-makers.htm#tab-2',
  'Printing Press Operators': 'https://www.onetonline.org/link/summary/51-5112.00',
  'Shoe and Leather Workers and Repairers': 'https://www.onetonline.org/link/summary/51-6041.00',
  'Tailors, Dressmakers, and Custom Sewers': 'https://www.onetonline.org/link/summary/51-6052.00',
  'Patternmakers, Wood': 'https://www.onetonline.org/link/summary/51-7032.00',
  'Water and Wastewater Treatment Plant and System Operators': 'https://www.bls.gov/ooh/production/water-and-wastewater-treatment-plant-and-system-operators.htm#tab-2',
  'Chemical Plant and System Operators': 'https://www.onetonline.org/link/summary/51-8091.00',
  'Petroleum Pump System Operators, Refinery Operators, and Gaugers': 'https://www.onetonline.org/link/summary/51-8093.00',
  'Chemical Equipment Operators and Tenders': 'https://www.onetonline.org/link/summary/51-9011.00',
  'Medical Appliance Technicians': 'https://www.bls.gov/ooh/production/dental-and-ophthalmic-laboratory-technicians-and-medical-appliance-technicians.htm#tab-2',
  'Painting, Coating, and Decorating Workers': 'https://www.bls.gov/ooh/production/painting-and-coating-workers.htm#tab-2',
  'Potters, Manufacturing': 'https://www.onetonline.org/link/summary/51-9195.05',
  'Flight Attendants': 'https://www.bls.gov/ooh/transportation-and-material-moving/flight-attendants.htm#tab-2',
  'Ambulance Drivers and Attendants': 'https://www.onetonline.org/link/summary/53-3011.00',
  'Heavy and Tractor-Trailer Truck Drivers': 'https://www.bls.gov/ooh/transportation-and-material-moving/heavy-and-tractor-trailer-truck-drivers.htm#tab-2',
  'Rail Yard Engineers, Dinkey Operators, and Hostlers': 'https://www.bls.gov/ooh/transportation-and-material-moving/railroad-occupations.htm#tab-2',
  'Sailors and Marine Oilers': 'https://www.bls.gov/ooh/transportation-and-material-moving/water-transportation-occupations.htm#tab-2',
  'Motorboat Operators': 'https://www.bls.gov/ooh/transportation-and-material-moving/water-transportation-occupations.htm#tab-2',
  'Ship Engineers': 'https://www.bls.gov/ooh/architecture-and-engineering/marine-engineers-and-naval-architects.htm#tab-2',
} as Record<Occupation, string>

export default links;
