import { useEffect, useMemo } from 'react';
import classnames from 'classnames';

import { AspectRatioView, ButtonRect } from 'components';
import { useAppStateActions, useTranslation } from 'appState';
import { ButtonType } from 'components/ButtonRect/ButtonRect.types';
import s from './BRET.module.scss';
import Square from './Square';
import { useBretData, useBretGameActions } from './state';
import { BretRoundStage } from './types';
import BretFeedback from './BretFeedback';
import GameSkipper from 'components/game/GameSkipper/GameSkipper';

type BRETProps = {
  isPracticeMode?: boolean,
};

const Game: React.FC<BRETProps> = ({ isPracticeMode }) => {
  const lang = useTranslation('BRET');
  const {
    squares,
    collectedParcels,
    totalMoney,
    currentStage,
    didGameFinish,
    didBombExplode,
    parcelWithBomb,
  } = useBretData();
  const {
    startRound,
    stopRound,
    togglePracticeMode,
    clearData,
  } = useBretGameActions();
  const { gameFlowForward } = useAppStateActions();
  const isFeedbackVisible = currentStage !== BretRoundStage.DEFAULT
    && currentStage !== BretRoundStage.COLLECTING && !isPracticeMode;

  const allMoneyCollected = useMemo(() => totalMoney.reduce((a, b) => a + b, 0), [totalMoney]);

  useEffect(() => togglePracticeMode(!!isPracticeMode), [isPracticeMode, togglePracticeMode]);

  useEffect(() => {
    if (didGameFinish) {
      gameFlowForward();
      clearData();
    }
  }, [didGameFinish, gameFlowForward, clearData]);

  return (
    <AspectRatioView background="#2a2929">
      <GameSkipper className={s.skipButton} />
      <div className={s.container}>
        <div className={s.gameArea}>
          <div className={s.board}>
            {squares.map(({ id }) => (<Square key={id} id={id} />))}
          </div>
          <div className={s.buttons}>
            <ButtonRect
              onClick={startRound}
              text={lang.play}
              className={classnames(s.bretBtn, {
                [s.disabled]: currentStage !== BretRoundStage.DEFAULT,
              })}
              type={ButtonType.PLAY}
            />
            <ButtonRect
              onClick={stopRound}
              text={lang.stop}
              className={classnames(s.bretBtn, {
                [s.disabled]: currentStage !== BretRoundStage.COLLECTING,
              })}
              type={ButtonType.STOP}
            />
          </div>
          <BretFeedback />
        </div>
        <div className={s.gameResults}>
          <h4 className={classnames(s.coinsSoFar, {
            [s.green]: isFeedbackVisible && !didBombExplode,
            [s.red]: isFeedbackVisible && didBombExplode,
          })}
          >
            {didBombExplode ? '0' : `${(collectedParcels)}`}
          </h4>
          <div>
            <p>{lang.parcelsCollected}</p>
            <p className={s.bold}>{allMoneyCollected}</p>
          </div>
          <div>
            <p>{lang.remainingParcels}</p>
            <p className={s.bold}>{25 - collectedParcels}</p>
          </div>
          <div
            className={classnames(s.bombInfo, {
              [s.visible]: isFeedbackVisible,
              [s.red]: didBombExplode,
              [s.green]: !didBombExplode,
            })}
          >
            <p>{lang.bombParcel}</p>
            <p className={s.bold}>{parcelWithBomb}</p>
          </div>
        </div>
      </div>
    </AspectRatioView>
  );
};

export default Game;
