import { useTranslation } from 'appState';
import s from './MTPT.module.scss';

const TooSlow = () => (
  <div className={s.tooSlowNotification}>
    <p className={s.text}>
      {useTranslation('MTPT').tooSlow}
    </p>
  </div>
);

export default TooSlow;
