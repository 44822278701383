import classNames from 'classnames';
import { GameMessageType } from 'types/enums';

import s from 'components/GameModal/GameModal.module.scss';
import { useTranslation } from 'appState';

const Message: React.FC<{ messageType: GameMessageType }> = ({
  messageType,
}) => {
  const lang = useTranslation('Message');

  return (
    <div className={s.feedbackToast}>
      <div className={classNames(s.feedbackIcon, s[messageType])} />
      <h6>{lang[messageType]}</h6>
    </div>
  );
};

export default Message;
