import { useEffect } from 'react';

import {
  Layout,
  Overview,
  CardCarousel,
  Heading,
  SkillStrengthCard,
  TextSection,
  LearningCenter,
} from 'components/assessment';
import { useAssessmentState, useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import { useAssessmentVisibility, useTranslation } from 'appState';
import useEntrepreneurshipTracker from './useEntrepreneurshipTracker';

import { Section } from './enums';
import useLinks from './useLinks';
import { fillTranslationTextWithVariables } from '../../../hooks';

type Props = {
  username: string;
  testDate: string;
  skillStrengths: {
    id: string;
    percentage?: number;
    type: 'emotionalIntelligence'
    | 'cognitive'
    | 'personality'
    | 'behavior',
    category: string;
    name?: string;
    description: string;
    popupContent?: React.ReactElement[] | React.ReactElement;
  }[];
};

const Entrepreneurship: React.FC<Props> = ({
  username,
  testDate,
  skillStrengths,
}) => {
  const lang = useTranslation('Assesment');
  const { entrepreneurship: courses, networksAndInnovations: networksLinks } = useLinks();
  const visibility = useAssessmentVisibility();
  const { setVisitedEntrepreneurship } = useAssessmentStateActions();
  const visitedEntrepreneurship = useAssessmentState(s => (
    s.currentVisitation?.visitedEntrepreneurship
  ));
  const { entrepreneurshipRef } = useEntrepreneurshipTracker();

  useEffect(() => {
    if (!visitedEntrepreneurship) {
      setVisitedEntrepreneurship();
    }
  }, [setVisitedEntrepreneurship, visitedEntrepreneurship]);

  return (
    <Layout.Content.ObservableSection
      id="entrepreneurship"
      ref={entrepreneurshipRef}
    >
      <Layout.Content.Section id={Section.EntrepreneurshipOverview}>
        <Overview
          username={username}
          testDate={testDate}
          heading={lang.entrepreneurshipOverview.heading}
          text={fillTranslationTextWithVariables(lang.entrepreneurshipOverview.text, { username })}
        />
      </Layout.Content.Section>
      <Layout.Content.Section
        id={Section.EntrepreneurshipSkillStrenghts}
        showIf={
          visibility.feedbackVisibilityOptions.entrepreneurshipStrengths
          && skillStrengths.length > 0
        }
      >
        <Heading>{lang.skillStrengthsHeading}</Heading>
        <CardCarousel>
          {
            skillStrengths.map(({
              id,
              category,
              name,
              description,
              type,
              popupContent,
            }) => (
              <SkillStrengthCard
                key={`${id}-entrepreneurship`}
                title={category}
                subtitle={name}
                description={description}
                type={type}
                popupContent={popupContent}
                interactionType="entrepreneurshipInteractions"
              />
            ))
          }
        </CardCarousel>
      </Layout.Content.Section>
      <Layout.Content.Section>
        <Heading>{lang.entrepreneurshipNetworks.heading}</Heading>
        <LearningCenter
          resources={networksLinks}
          interactionType="entrepreneurshipInteractions"
        />
      </Layout.Content.Section>
      <Layout.Content.Section
        id={Section.EntrepreneurshipCourses}
        showIf={visibility.feedbackVisibilityOptions.entrepreneurshipCourses}
      >
        <Heading>{lang.entrepreneurshipCourses.heading}</Heading>
        <TextSection>{lang.entrepreneurshipCourses.text}</TextSection>
        <LearningCenter
          resources={courses}
          interactionType="entrepreneurshipInteractions"
        />
      </Layout.Content.Section>
    </Layout.Content.ObservableSection>
  );
};

export default Entrepreneurship;
