import { useAppStateActions, useTranslation } from 'appState';
import { AspectRatioView } from 'components';
import { useEffect } from 'react';

const LoggingIn: React.FC = () => {
  const { appFlowForward } = useAppStateActions();
  useEffect(() => {
    setTimeout(appFlowForward, 1000);
  }, [appFlowForward]);
  const lang = useTranslation("LoggingIn");

  return (
    <AspectRatioView background="#353F97">
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '10rem',
          color: 'white',
        }}
      >
        {lang.pleaseWait}...
      </div>
    </AspectRatioView>
  );
};

export default LoggingIn;
