import { useState } from 'react';
import classNames from 'classnames';

import ButtonRect from 'components/ButtonRect/ButtonRect';
import { useAppStateActions, useTranslation } from 'appState';
import { ALTERNATIVE_GAME_FLOW } from 'config';
import s from './GameSkipper.module.scss';
import GameModal from 'components/GameModal/GameModal';

type GameSkipperProps = {
  className?: string;
  onOpen?: () => void;
  onContinue?: () => void;
};

const GameSkipper: React.FC<GameSkipperProps> = ({
  className,
  onOpen,
  onContinue,
}) => {
  const [confirmationPrompt, setConfirmationPrompt] = useState(false);
  const lang = useTranslation('GameSkipper');
  const { skipCurrentGame } = useAppStateActions();

  if (!ALTERNATIVE_GAME_FLOW) {
    return null;
  }

  return (
    <>
      <GameModal isVisible={confirmationPrompt}>
        <GameModal.SkipGame
          onConfirm={skipCurrentGame}
          onCancel={() => {
            onContinue?.();
            setConfirmationPrompt(false);
          }}
        />
      </GameModal>
      {!confirmationPrompt && (
        <ButtonRect
          className={classNames(s.button, className)}
          text={lang.skipButton}
          onClick={() => {
            onOpen?.();
            setConfirmationPrompt(true);
          }}
        />
      )}
    </>
  );
};

export default GameSkipper;
