// @ts-nocheck

import { Occupation } from 'types';

// TODO: retrieve from api
const greenOccupations: Array<Occupation> = [
  'General and Operations Managers',
  'Industrial Production Managers',
  'Human Resources Specialists',
  'Architects',
  'Wind Energy Engineers',
  'Solar Energy Systems Engineers',
  'Civil Engineering Technicians',
  'Environmental Engineering Technicians',
  'Industrial Engineering Technicians',
  'Animal Scientists',
  'Food Scientists and Technologists',
  'Soil and Plant Scientists',
  'Range Managers',
  'Park Naturalists',
  'Foresters',
  'Epidemiologists',
  'Medical Scientists',
  'Chemical Technicians',
  'Quality Control Analysts',
  'Health Education Specialists',
  'Commercial and Industrial Designers',
  'Floral Designers',
  'Graphic Designers',
  'Merchandise Displayers',
  'Set and Exhibit Designers',
  'Veterinarians',
  'Forest Fire Inspectors and Prevention Specialists',
  'Fish and Game Wardens',
  'Forest and Conservation Workers',
  'Logging Equipment Operators',
  'Tile and Stone Setters',
  'Automotive Body and Related Repairers',
  'Commercial Divers',
  'Machinists',
  'Water and Wastewater Treatment Plant and System Operators',
];

export default greenOccupations;
