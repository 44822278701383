import { useEffect, useState } from 'react';

import {
  AspectRatioView, ButtonRect, GameModal, Timer,
} from 'components';
import { useTranslation, useVirtualViewportSize } from 'appState';

import Shape1 from './shapes/Shape1';
import Shape2 from './shapes/Shape2';
import Shape3 from './shapes/Shape3';
import { GameStage, MTPTStageResult } from './types';
import Cursor from './Cursor';
import Trace from './Trace';
import TooSlow from './TooSlow';
import useGameState from './useGameState';
import s from './MTPT.module.scss';

const useScrollLock = (lock = true) => useEffect(() => {
  if (!lock) {
    return;
  }
  const originalOverflow = document.body.style.overflow;
  document.body.style.overflow = 'hidden';
  return () => {
    document.body.style.overflow = originalOverflow;
  };
}, [lock]);


type MTPTGameProps = {
  stage: GameStage;
  totalTime: number;
  feedbackHeader: string;
  feedbackText?: string;
  onFinish: (results: MTPTStageResult) => void;
  quittable?: boolean;
  showTimer?: boolean;
};

const MTPTGame: React.FC<MTPTGameProps> = ({
  stage,
  totalTime,
  feedbackHeader,
  feedbackText,
  quittable,
  onFinish,
  showTimer,
}) => {
  const vp = useVirtualViewportSize();
  const lang = useTranslation('MTPT');

  const {
    start,
    cleanup,
    quit,
    setGameAreaRef,
    startTracking,
    track,
    cancelTracking,
    onTrackingError,
    trackedPoints,
    hasError,
    isTracking,
    isCompleted,
    setProgress,
    didHesitate,
    remainingTime,
    didTimeOut,
    didQuit,
    getResults,
  } = useGameState();

  const [startingPointRef, setStartingPointRef] = useState<SVGEllipseElement | null>(null);

  useEffect(
    () => {
      let x = 0;
      let y = 0;
      if (startingPointRef) {
        const posX = startingPointRef.getBoundingClientRect().x
          + startingPointRef.getBoundingClientRect().width * 0.5;
        const posY = startingPointRef.getBoundingClientRect().y
          + startingPointRef.getBoundingClientRect().height;
        x = Math.floor( posX / window.innerWidth * 1000) / 1000
        y = Math.floor(posY / vp.height * 1000) / 1000
      }
      const startingPoint = {x, y};
      start(startingPoint, totalTime)
    },
    [start, stage, totalTime, startingPointRef, vp.height],
  );
  useEffect(() => {
    return () => {
      cleanup();
    }
  }, [cleanup]);

  useScrollLock(isTracking);

  useEffect(() => {
    document.addEventListener('pointercancel', cancelTracking);
    document.addEventListener('pointerup', cancelTracking);
    document.addEventListener('pointerout', cancelTracking);
    document.addEventListener('pointerleave', cancelTracking);
    return () => {
      document.removeEventListener('pointercancel', cancelTracking);
      document.removeEventListener('pointerup', cancelTracking);
      document.removeEventListener('pointerout', cancelTracking);
      document.removeEventListener('pointerleave', cancelTracking);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const end = () => {
    onFinish({
      isPractice: stage !== GameStage.mainRun,
      ...getResults(),
    });
  };

  const lastPoint = trackedPoints[trackedPoints.length - 1] ?? { x: -1, y: -1 };
  const cursor = {
    x: lastPoint.x * window.innerWidth,
    y: lastPoint.y * vp.height,
  };

  const Shape = {
    [GameStage.trialRunA]: Shape1,
    [GameStage.trialRunB]: Shape2,
    [GameStage.mainRun]: Shape3,
  }[stage];

  return (
    <AspectRatioView>
      <div
        className={s.wrapper}
        style={{ touchAction: 'none' }}
      >
        <Shape
          onError={onTrackingError}
          onProgress={setProgress}
          isFinished={isCompleted}
          hasError={hasError}
          cursor={cursor}
          isTracking={isTracking}
          setStartingPointRef={setStartingPointRef}
        />
        <Trace
          points={trackedPoints}
          isFinished={isCompleted}
        />
        <div className={s.cursorArea}>
          <Cursor
            isVisible={!hasError && !isCompleted}
            tracingMode={isTracking}
            x={cursor.x}
            y={cursor.y}
          />
        </div>
        <div
          className={s.activeArea}
          ref={setGameAreaRef}
          onPointerDown={startTracking}
          onPointerMove={track}
          onContextMenu={(e) => e.preventDefault()}
          style={{ touchAction: isTracking ? 'none' : 'auto' }}
        />
      </div>
      { showTimer && <Timer>{remainingTime}</Timer>}
      { didHesitate && <TooSlow /> }
      {
        quittable && (
          <ButtonRect
            text={lang.quit}
            onClick={quit}
            className={s.quitButton}
            isVisible={!isTracking}
          />
        )
      }
      <GameModal
        isVisible={isCompleted || didTimeOut || didQuit}
        delay={isCompleted ? 2000 : 0}
      >
        {didQuit ? (
          <GameModal.Success
            header={lang.stageFeedback[0].header}
            text={lang.stageFeedback[0].text}
            onClick={end}
          />
        ) : (
          <GameModal.Success
            header={feedbackHeader}
            text={feedbackText || ''}
            onClick={end}
          />
        )}

      </GameModal>
    </AspectRatioView>
  );
};

export default MTPTGame;
