import { useAppState, useAppStateActions, useLanguageDirection, useTranslation } from 'appState';
import { AspectRatioView, ButtonRect, HorizontalOrientationPrompt } from 'components';

import s from './GamesCompleted.module.scss';

const GamesCompleted: React.FC = () => {
  const willRedirect = useAppState(s => s.maybeRedirectOnGamesCompletion())
  const dir = useLanguageDirection();

  const { appFlowForward } = useAppStateActions();
  const lang = useTranslation('GamesCompleted');

  if (willRedirect) {
    return null;
  }

  return (
    <>
      <HorizontalOrientationPrompt />
      <AspectRatioView>
        <div className={s.container}>
          <h3 className={s.title} dir={dir}>{lang.title}</h3>
          <p className={s.message} dir={dir}>{lang.message}
          </p>
          <ButtonRect onClick={appFlowForward} text={lang.buttonLabel} />
        </div>
      </AspectRatioView>
    </>
  );
};

export default GamesCompleted;
