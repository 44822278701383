import { useEffect } from 'react';

import { GameId } from 'types/enums';
import { useAppStateActions, useTranslation } from 'appState';
import { AspectRatioView, GameModal } from 'components';
import { ProgressBar } from 'components/game';

import styles from './CPT.module.scss';
import { useCPTGameActions, useCPTGameData } from './state';
import { CPTGameProps } from './CPTGame.types';
import { CPTGameModal, CPTGameResultForSubmission, CPTStage } from './state/CPTState';
import GameSkipper from 'components/game/GameSkipper/GameSkipper';

const CPTGame: React.FC<CPTGameProps> = ({
  practiceMode = false,
  extendedMode = false,
}) => {
  const {
    gameStage,
    letterToDisplay,
    lettersBlock,
    displayGameModal,
    gameModalToDisplay,
    gameFinished,
    gameResult,
    progress,
  } = useCPTGameData();
  const {
    togglePracticeMode,
    startNewGame,
    onLetterClick,
    toggleExtendedMode,
    resetGameStage,
    continueCurrentBlock,
    pauseCurrentBlock,
    saveGameResult,
  } = useCPTGameActions();
  const { gameFlowForward } = useAppStateActions();
  const lang = useTranslation('CPT');
  const { submitGameResults } = useAppStateActions();

  useEffect(
    () => {
      togglePracticeMode(!!practiceMode);
      toggleExtendedMode(!!extendedMode);
    },
    [practiceMode, togglePracticeMode, extendedMode, toggleExtendedMode],
  );

  useEffect(startNewGame, [startNewGame]);

  useEffect(() => resetGameStage, [resetGameStage]);

  useEffect(() => {
    if (gameStage === CPTStage.FINISHED) {
      gameFlowForward();
      saveGameResult();
    }
  }, [gameStage, gameFlowForward, saveGameResult]);

  useEffect(() => {
    if (gameFinished) {
      const results: CPTGameResultForSubmission = {
        ...gameResult,
        cptGameScores: gameResult.cptGameScores.map(score => ({
          ...score,
          responses: score.responses.join(' '),
        })),
        cptGameScoresExtended: gameResult.cptGameScoresExtended.map(score => ({
          ...score,
          responses: score.responses.join(' '),
        })),
      };
      submitGameResults(GameId.CPT, results);
    }
  }, [gameFinished, gameResult, submitGameResults]);

  const renderGameStage = () => {
    switch (gameStage) {
      case (CPTStage.SHOW_LETTERS):
        return (
          <div className={styles.Letter} key={letterToDisplay} data-cy='letter'>
            {lettersBlock[letterToDisplay]}
          </div>
        );
      case (CPTStage.BLOCK_END):
        return (
          <div className={styles.BlockEnd} />
        );
      default:
        return null;
    }
  };

  const renderGameModal = () => {
    switch (gameModalToDisplay) {
      case (CPTGameModal.TARGET_CORRECT):
        return (
          <GameModal.Success
            onClick={continueCurrentBlock}
            text={lang.targetCorrectMsg}
            header={lang.success}
          />
        );
      case (CPTGameModal.TARGET_INCORRECT):
        return (
          <GameModal.Incorrect
            onClick={continueCurrentBlock}
            header={lang.incorrect}
            text={!extendedMode ? lang.targetIncorrectMsg : lang.targetIncorrectMsgExtended}
          />
        );
      case (CPTGameModal.TARGET_MISSED):
        return (
          <GameModal.Incorrect
            onClick={continueCurrentBlock}
            header={lang.incorrect}
            text={!extendedMode ? lang.targetMissedMsg : lang.targetMissedMsgExtended}
          />
        );
      default:
        return null;
    }
  };

  const renderOverlayElement = () => {
    switch (gameStage) {
      case (CPTStage.SHOW_LETTERS):
      case (CPTStage.LETTER_REST):
        return <div className={styles.ClickCapture} onClick={onLetterClick} data-cy='clickLetter'/>;
      case (CPTStage.PAUSE):
        return (
          <div className={styles.Pause} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <AspectRatioView>
        <div className={styles.Container}>
          <div className={styles.LetterContainer}>
            {
              renderGameStage()
            }
          </div>
        </div>
      </AspectRatioView>
      <GameSkipper
        className={styles.SkipButton}
        onOpen={pauseCurrentBlock}
        onContinue={continueCurrentBlock}
      />
      <div className={styles.Overlay}>
        {
          renderOverlayElement()
        }
      </div>
      <GameModal isVisible={displayGameModal}>
        {
          renderGameModal()
        }
      </GameModal>
      <ProgressBar progress={progress} />
    </>
  );
};

export default CPTGame;
