import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import s from './pages.module.scss';
import { useTranslation } from 'appState';

const AboutPage: React.FC = () => {
  const lang = useTranslation('pages');
  return (
    <main className={s.wrapper}>
      <h1 className={s.heading}>{lang.about.heading}</h1>
      <ReactMarkdown>
        {lang.about.copy}
      </ReactMarkdown>
      <Link className={s.homeLink} to="/">{lang.homepageBtn}</Link>
    </main>
  );
};

export default AboutPage;
