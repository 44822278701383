import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { Button, Box } from '@mui/material';

import { objectEntries } from 'utils';
import {
  ErrorMessage,
  QuestionnaireWrapper,
  Radio,
  // Select,
  // Text,
} from 'components/questionnaire';
import {
  FivePointScale,
  // HowMany,
  // JobSector,
  // PlanToIncreaseJobSearch,
  // YesOrNo,
} from 'types/enums';
import { PostAssessmentQuestions } from 'types';

import { FormValues } from './FormValues';
import makeValidator from './makeValidator';
import { stripNulls } from '../helpers';
import { useTranslation } from 'appState';

type Props = {
  onSubmit: (values: Partial<PostAssessmentQuestions>) => Promise<void>;
  onQuit: () => void;
};

const PostAssessmentQuestionnaireNoFeedback: React.FC<Props> = ({
  onSubmit,
}) => {
  const lang = useTranslation('Questionnaire');
  const validator = useMemo(() => makeValidator(lang.errors), [lang.errors]);

  return (
    <QuestionnaireWrapper>
      <Formik
        initialValues={{
          postAssessmentQuestionsAnswered: true,
          reportUsefullness: '',
          jobStrengthsAndWeaknesses: '',
          helpJobSearch: '',
          helpGettingJob: '',
          planToIncreaseJobSearch: '',
          targetSector: '',
          otherTargetSector: '',
          howManyHoursWillSearchPerWeek: '',
          howManyApplicationsNextMonth: '',
          willSkillsAttachHelpGetInterview: '',
          plansToAttachSkillsReport: '',
          expectsToFindJobInSixMonths: '',
          wageOfSimilarEmployeesAfter: '',
          lowestAcceptablePayAfter: '',
          accessedLearningLinks: '',
          accessedLearningLinksWhyNot: '',
          howManyHoursToUpskillPerWeek: '',
          helpGetInterview: '',
          helpGetJobInDesiredSector: '',
          inspiredToDevelopFurther: '',
          likelyToRecommendSkillcraft: '',
        } as FormValues}
        validate={validator}
        onSubmit={(form) => {
          const values = stripNulls({
            postAssessmentQuestionsAnswered: true,
            reportUsefullness:
              form.reportUsefullness !== ''
                ? form.reportUsefullness : null,
            jobStrengthsAndWeaknesses:
              form.jobStrengthsAndWeaknesses !== ''
                ? form.jobStrengthsAndWeaknesses : null,
            helpJobSearch:
              form.helpJobSearch !== ''
                ? form.helpJobSearch : null,
            helpGettingJob:
              form.helpGettingJob !== ''
                ? form.helpGettingJob : null,
            planToIncreaseJobSearch:
              form.planToIncreaseJobSearch !== ''
                ? form.planToIncreaseJobSearch : null,
            howManyHoursWillSearchPerWeek:
              form.howManyHoursWillSearchPerWeek !== ''
                ? form.howManyHoursWillSearchPerWeek : null,
            howManyApplicationsNextMonth:
              form.howManyApplicationsNextMonth !== ''
                ? form.howManyApplicationsNextMonth : null,
            expectsToFindJobInSixMonths:
              form.expectsToFindJobInSixMonths !== ''
                ? form.expectsToFindJobInSixMonths : null,
            targetSector: form.targetSector !== ''
              ? form.targetSector : null,
            otherPreferredSector: form.otherTargetSector !== ''
              ? form.otherTargetSector : null,
            wageOfSimilarEmployeesAfter: form.wageOfSimilarEmployeesAfter !== ''
              ? form.wageOfSimilarEmployeesAfter : null,
            lowestAcceptablePayAfter: form.lowestAcceptablePayAfter !== ''
              ? form.lowestAcceptablePayAfter : null,
            inspiredToDevelopFurther: form.inspiredToDevelopFurther !== ''
              ? form.inspiredToDevelopFurther : null,
            likelyToRecommendSkillcraft: form.likelyToRecommendSkillcraft !== ''
              ? form.likelyToRecommendSkillcraft : null,
          });
          onSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          submitForm,
          isSubmitting,
        }) => (
          <Form>

            {/* 20. JOB RELATED STRENGTHS AND WEAKNESSES */}
            <Radio
              name="jobStrengthsAndWeaknesses"
              question={lang.questions.postAssessment.jobStrengthsAndWeaknesses}
              isTouched={touched.jobStrengthsAndWeaknesses}
              error={errors.jobStrengthsAndWeaknesses}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.jobStrengthsAndWeaknessesFivePointScale[
                  value as keyof typeof lang.jobStrengthsAndWeaknessesFivePointScale
                ],
              }))}
            />

            {/* 19. REPORT USEFULLNESS */}
            <Radio
              name="reportUsefullness"
              question={lang.questions.postAssessment.reportUsefullness}
              isTouched={touched.reportUsefullness}
              error={errors.reportUsefullness}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.fivePointScale[value  as keyof typeof lang.fivePointScale],
              }))}
            />

            {/* 21. HELP JOB SEARCH */}
            {/*<Radio*/}
            {/*  name="helpJobSearch"*/}
            {/*  question={lang.questions.postAssessment.helpJobSearch}*/}
            {/*  isTouched={touched.helpJobSearch}*/}
            {/*  error={errors.helpJobSearch}*/}
            {/*  items={objectEntries(FivePointScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.fivePointScale[value as keyof typeof lang.fivePointScale],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 22. HELP GETTING A JOB */}
            {/*<Radio*/}
            {/*  name="helpGettingJob"*/}
            {/*  question={lang.questions.postAssessment.helpGettingJob}*/}
            {/*  isTouched={touched.helpGettingJob}*/}
            {/*  error={errors.helpGettingJob}*/}
            {/*  items={objectEntries(FivePointScale).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.fivePointScale[value as keyof typeof lang.fivePointScale],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 23. WILL INCREASE JOB SEARCH */}
            {/*<Radio*/}
            {/*  name="planToIncreaseJobSearch"*/}
            {/*  question={lang.questions.postAssessment.planToIncreaseJobSearch}*/}
            {/*  isTouched={touched.planToIncreaseJobSearch}*/}
            {/*  error={errors.planToIncreaseJobSearch}*/}
            {/*  items={objectEntries(PlanToIncreaseJobSearch).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.planToIncreaseJobSearch[*/}
            {/*      value as keyof typeof lang.planToIncreaseJobSearch*/}
            {/*    ],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 24. TARGET SECTOR */}
            {/*<Select*/}
            {/*  name="targetSector"*/}
            {/*  question={lang.questions.postAssessment.targetSector}*/}
            {/*  error={errors.targetSector}*/}
            {/*  items={objectEntries(JobSector).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.jobSector[value as keyof typeof lang.jobSector],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 24.5 OTHER SECTOR */}
            {/*<Text*/}
            {/*  showIf={values.targetSector === JobSector.Other}*/}
            {/*  name="otherTargetSector"*/}
            {/*  question={lang.questions.postAssessment.otherTargetSector}*/}
            {/*  error={errors.otherTargetSector}*/}
            {/*/>*/}

            {/* 25. HOW MANY HOURS JOB SEARCHING PER WEEK */}
            {/*<Select*/}
            {/*  name="howManyHoursWillSearchPerWeek"*/}
            {/*  question={lang.questions.postAssessment.howManyHoursWillSearchPerWeek}*/}
            {/*  error={errors.howManyHoursWillSearchPerWeek}*/}
            {/*  items={objectEntries(HowMany).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.howMany[value as keyof typeof lang.howMany],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 26. HOW MANY APPLICATIONS IN NEXT MONTH */}
            {/*<Select*/}
            {/*  name="howManyApplicationsNextMonth"*/}
            {/*  question={lang.questions.postAssessment.howManyApplicationsNextMonth}*/}
            {/*  error={errors.howManyApplicationsNextMonth}*/}
            {/*  items={objectEntries(HowMany).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.howMany[value as keyof typeof lang.howMany],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 29. EXECTS TO FIND JOB IN 6 MONTHS */}
            {/*<Radio*/}
            {/*  name="expectsToFindJobInSixMonths"*/}
            {/*  question={lang.questions.postAssessment.expectsToFindJobInSixMonths}*/}
            {/*  isTouched={touched.expectsToFindJobInSixMonths}*/}
            {/*  error={errors.expectsToFindJobInSixMonths}*/}
            {/*  items={objectEntries(YesOrNo).map(([, value]) => ({*/}
            {/*    value,*/}
            {/*    label: lang.yesOrNo[value as keyof typeof lang.yesOrNo],*/}
            {/*  }))}*/}
            {/*/>*/}

            {/* 30. TYPICAL MONTHLY WAGE FOR PEOPLE LIKE YOU */}
            {/*<Text*/}
            {/*  name="wageOfSimilarEmployeesAfter"*/}
            {/*  question={lang.questions.postAssessment.wageOfSimilarEmployeesAfter}*/}
            {/*  error={errors.wageOfSimilarEmployeesAfter}*/}
            {/*/>*/}

            {/* 31. LOWEST ACCEPTABLE WAGE */}
            {/*<Text*/}
            {/*  name="lowestAcceptablePayAfter"*/}
            {/*  question={lang.questions.postAssessment.lowestAcceptablePayAfter}*/}
            {/*  error={errors.lowestAcceptablePayAfter}*/}
            {/*/>*/}


            {/* 39. INSPIRED TO DEVELOP FURTHER */}
            <Radio
              name="inspiredToDevelopFurther"
              question={lang.questions.postAssessment.inspiredToDevelopFurther}
              isTouched={touched.inspiredToDevelopFurther}
              error={errors.inspiredToDevelopFurther}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.likelinessFivePointScale[
                  value as keyof typeof lang.likelinessFivePointScale
                ],
              }))}
            />

            {/* 38. RECOMMEND SKILLCRAFT */}
            <Radio
              name="likelyToRecommendSkillcraft"
              question={lang.questions.postAssessment.likelyToRecommendSkillcraft}
              isTouched={touched.likelyToRecommendSkillcraft}
              error={errors.likelyToRecommendSkillcraft}
              items={objectEntries(FivePointScale).map(([, value]) => ({
                value,
                label: lang.likelinessFivePointScale[
                  value as keyof typeof lang.likelinessFivePointScale
                ],
              }))}
            />

            {/* SUBMIT */}
            <Box margin={1}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {lang.submit}
              </Button>
            </Box>

            {(errors as any).submissionError && (
              <ErrorMessage>{lang.submissionError}</ErrorMessage>
            )}
            {!!Object.entries(errors).length && (
              <ErrorMessage>{lang.missingEntriesError}</ErrorMessage>
            )}
          </Form>
        )}
      </Formik>
    </QuestionnaireWrapper>
  );
};

export default PostAssessmentQuestionnaireNoFeedback;
