import { Occupation } from 'types';

// TODO: retrieve from api
const iconTypes = {
  'General and Operations Managers': 'E',
  'Marketing Managers': 'E',
  'Sales Managers': 'E',
  'Industrial Production Managers': 'E',
  'Human Resources Managers': 'E',
  'Construction Managers': 'E',
  'Education and Childcare Administrators, Preschool and Daycare': 'S',
  'Food Service Managers': 'E',
  'Water Resource Specialists': 'I',
  'Postmasters and Mail Superintendents': 'E',
  'Property, Real Estate, and Community Association Managers': 'E',
  'Social and Community Service Managers': 'E',
  'Buyers and Purchasing Agents': 'E',
  'Environmental Compliance Inspectors': 'C',
  'Human Resources Specialists': 'I',
  'Farm Labor Contractors': 'E',
  'Meeting, Convention, and Event Planners': 'E',
  'Sustainability Specialists': 'C',
  'Online Merchants': 'E',
  'Personal Financial Advisors': 'E',
  'Loan Officers': 'E',
  'Actuaries': 'C',
  'Operations Research Analysts': 'I',
  'Statisticians': 'C',
  'Architects': 'A',
  'Surveyors': 'R',
  'Agricultural Engineers': 'I',
  'Chemical Engineers': 'I',
  'Civil Engineers': 'R',
  'Transportation Engineers': 'R',
  'Electrical Engineers': 'I',
  'Mechanical Engineers': 'I',
  'Automotive Engineers': 'R',
  'Wind Energy Engineers': 'R',
  'Solar Energy Systems Engineers': 'R',
  'Civil Engineering Technicians': 'R',
  'Environmental Engineering Technicians': 'R',
  'Industrial Engineering Technicians': 'I',
  'Automotive Engineering Technicians': 'R',
  'Animal Scientists': 'I',
  'Food Scientists and Technologists': 'I',
  'Soil and Plant Scientists': 'I',
  'Range Managers': 'R',
  'Park Naturalists': 'S',
  'Foresters': 'R',
  'Epidemiologists': 'I',
  'Medical Scientists': 'I',
  'Environmental Scientists and Specialists': 'I',
  'Geographers': 'I',
  'Transportation Planners': 'I',
  'Chemical Technicians': 'I',
  'Social Science Research Assistants': 'C',
  'Quality Control Analysts': 'C',
  'Educational, Guidance, and Career Counselors': 'S',
  'Child, Family, and School Social Workers': 'S',
  'Healthcare Social Workers': 'S',
  'Health Education Specialists': 'S',
  'Social and Human Service Assistants': 'C',
  'Community Health Workers': 'S',
  'Lawyers': 'E',
  'Paralegals and Legal Assistants': 'C',
  'Elementary School Teachers': 'S',
  'Special Education Teachers': 'S',
  'Adapted Physical Education Specialists': 'S',
  'Adult Educators': 'S',
  'Archivists': 'C',
  'Museum Technicians and Conservators': 'R',
  'Library Technicians': 'C',
  'Craft Artists': 'A',
  'Commercial and Industrial Designers': 'A',
  'Fashion Designers': 'A',
  'Floral Designers': 'A',
  'Graphic Designers': 'A',
  'Merchandise Displayers': 'A',
  'Set and Exhibit Designers': 'A',
  'Actors': 'A',
  'Poets, Lyricists and Creative Writers': 'A',
  'Interpreters and Translators': 'A',
  'Audio and Video Technicians': 'R',
  'Photographers': 'A',
  'Camera Operators, Television, Video, and Film': 'R',
  'Film and Video Editors': 'A',
  'Dentists': 'I',
  'Physician Assistants': 'S',
  'Occupational Therapists': 'S',
  'Physical Therapists': 'S',
  'Veterinarians': 'I',
  'Registered Nurses': 'S',
  'Nurse Practitioners': 'S',
  'Medical and Clinical Laboratory Technicians': 'R',
  'Dietetic Technicians': 'S',
  'Pharmacy Technicians': 'C',
  'Psychiatric Technicians': 'S',
  'Surgical Technologists': 'R',
  'Veterinary Technicians': 'R',
  'Licensed Practical and Licensed Vocational Nurses': 'S',
  'Midwives': 'S',
  'Occupational Therapy Assistants': 'S',
  'Physical Therapist Aides': 'S',
  'Massage Therapists': 'S',
  'Dental Assistants': 'C',
  'Medical Assistants': 'S',
  'Medical Equipment Preparers': 'R',
  'Pharmacy Aides': 'C',
  'Veterinary Assistants and Laboratory Animal Caretakers': 'R',
  'Speech-Language Pathology Assistants': 'S',
  'Forest Fire Inspectors and Prevention Specialists': 'R',
  'Fish and Game Wardens': 'R',
  'Transit and Railroad Police': 'R',
  'Animal Control Workers': 'R',
  'Chefs and Head Cooks': 'E',
  'Cooks, Private Household': 'A',
  'Food Preparation Workers': 'R',
  'Waiters and Waitresses': 'S',
  'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop': 'E',
  'Landscaping and Groundskeeping Workers': 'R',
  'Animal Caretakers': 'R',
  'Barbers': 'R',
  'Hairdressers, Hairstylists, and Cosmetologists': 'A',
  'Makeup Artists, Theatrical and Performance': 'A',
  'Manicurists and Pedicurists': 'R',
  'Skincare Specialists': 'E',
  'Tour Guides and Escorts': 'S',
  'Childcare Workers': 'S',
  'Residential Advisors': 'S',
  'Cashiers': 'C',
  'Retail Salespersons': 'E',
  'Travel Agents': 'E',
  'Sales Representatives': 'C',
  'Demonstrators and Product Promoters': 'E',
  'Real Estate Sales Agents': 'E',
  'Payroll and Timekeeping Clerks': 'C',
  'Procurement Clerks': 'C',
  'Tellers': 'C',
  'Customer Service Representatives': 'E',
  'Library Assistants, Clerical': 'C',
  'Human Resources Assistants': 'C',
  'Receptionists and Information Clerks': 'C',
  'Cargo and Freight Agents': 'C',
  'Postal Service Mail Carriers': 'C',
  'Secretaries and Administrative Assistants': 'C',
  'Data Entry Keyers': 'C',
  'Word Processors and Typists': 'C',
  'Statistical Assistants': 'C',
  'Animal Breeders': 'R',
  'Agricultural Equipment Operators': 'R',
  'Forest and Conservation Workers': 'R',
  'Logging Equipment Operators': 'R',
  'First-Line Supervisors of Construction Trades and Extraction Workers': 'E',
  'Boilermakers': 'R',
  'Stonemasons': 'R',
  'Tile and Stone Setters': 'R',
  'Cement Masons and Concrete Finishers': 'R',
  'Operating Engineers and Other Construction Equipment Operators': 'R',
  'Drywall and Ceiling Tile Installers': 'R',
  'Electricians': 'R',
  'Glaziers': 'R',
  'Painters, Construction and Maintenance': 'R',
  'Roofers': 'R',
  'Rail-Track Laying and Maintenance Equipment Operators': 'R',
  'Computer, Automated Teller, and Office Machine Repairers': 'R',
  'Electric Motor, Power Tool, and Related Repairers': 'R',
  'Security and Fire Alarm Systems Installers': 'R',
  'Automotive Body and Related Repairers': 'R',
  'Maintenance and Repair Workers, General': 'R',
  'Commercial Divers': 'R',
  'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers': 'R',
  'Engine and Other Machine Assemblers': 'R',
  'Bakers': 'R',
  'Butchers and Meat Cutters': 'R',
  'Machinists': 'R',
  'Tool and Die Makers': 'R',
  'Printing Press Operators': 'R',
  'Shoe and Leather Workers and Repairers': 'R',
  'Tailors, Dressmakers, and Custom Sewers': 'R',
  'Patternmakers, Wood': 'R',
  'Water and Wastewater Treatment Plant and System Operators': 'R',
  'Chemical Plant and System Operators': 'R',
  'Petroleum Pump System Operators, Refinery Operators, and Gaugers': 'R',
  'Chemical Equipment Operators and Tenders': 'R',
  'Medical Appliance Technicians': 'R',
  'Painting, Coating, and Decorating Workers': 'R',
  'Potters, Manufacturing': 'R',
  'Flight Attendants': 'E',
  'Ambulance Drivers and Attendants': 'R',
  'Heavy and Tractor-Trailer Truck Drivers': 'R',
  'Rail Yard Engineers, Dinkey Operators, and Hostlers': 'R',
  'Sailors and Marine Oilers': 'R',
  'Motorboat Operators': 'R',
  'Ship Engineers': 'R',
} as Record<Occupation, 'R' | 'I' | 'A' | 'S' | 'E' | 'C'>;

export default iconTypes;
