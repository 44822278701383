import { useEffect, useState } from 'react';
import cls from 'classnames';
import ReactMarkdown from 'react-markdown';

import {
  AspectRatioView, ButtonRect, Toast, Timer,
} from 'components';
import { GameMessageType } from 'types/enums';
import { ButtonType } from 'components/ButtonRect/ButtonRect.types';
import { TrialData, TrialScore } from './types';
import s from './HMT.module.scss';
import GameSkipper from 'components/game/GameSkipper/GameSkipper';
import { useLanguageDirection, useTranslation } from 'appState';

const TIME_LIMIT = 60_000;

enum Stage {
  Start,
  AnswerSelected,
  AnswerConfirmed,
  FeedbackEnded,
}

type Props = {
  data: TrialData;
  onFinish: (score: TrialScore) => void;
  explanation?: string;
  showTimer?: boolean;
};

const HMTTrial: React.FC<Props> = ({
  data: {
    name: trialName,
    pattern: patternImage,
    matches: selectableImages,
    correctMatch: expectedAnswer,
    practice: isPractice,
  },
  onFinish,
  explanation,
  showTimer,
}) => {
  const lang = useTranslation('HMT');

  const [hasTimedOut, setHasTimedOut] = useState(false);
  useEffect(() => {
    setHasTimedOut(false);
    const timeout = setTimeout(
      () => setHasTimedOut(true),
      TIME_LIMIT,
    );
    return () => {
      clearTimeout(timeout);
    }
  }, [setHasTimedOut, trialName]);

  const [stage, setStage] = useState(Stage.Start);
  const [answer, setAnswer] = useState<number | null>(null);

  const isSelectable = !hasTimedOut && (stage === Stage.Start || stage === Stage.AnswerSelected);
  const isExplanationVisible = !hasTimedOut && explanation && stage === Stage.FeedbackEnded;
  const isConfirmBtnVisible = stage === Stage.AnswerSelected && isPractice;
  const isNextBtnVisible = stage === (isPractice ? Stage.FeedbackEnded : Stage.AnswerSelected)
  || hasTimedOut;
  const isFeedbackVisible = stage === Stage.AnswerConfirmed;
  const isTimeoutInfoVisible = hasTimedOut && stage !== Stage.FeedbackEnded;
  const isFinished = stage === Stage.FeedbackEnded;
  const isCorrect = answer === expectedAnswer;
  const isSelectionWrong = !isCorrect && stage === Stage.FeedbackEnded;
  const shouldShowResult = isPractice;

  const [remainingTime, setRemainingTime] = useState(TIME_LIMIT);
  useEffect(() => {
    setRemainingTime(TIME_LIMIT);
    const intervalRef = setInterval(
      () => setRemainingTime(prevRemainingTime => Math.max(0, prevRemainingTime - 1000)),
      1000,
    );
    if (isFinished || isFeedbackVisible) {
      clearInterval(intervalRef);
    }
    return () => clearInterval(intervalRef);
  }, [isFinished, isFeedbackVisible, trialName]);

  const selectAnswer = (index: number) => {
    setAnswer(index);
    setStage(Stage.AnswerSelected);
  };
  const confirmAnswer = () => {
    setStage(Stage.AnswerConfirmed);
  }
  const endFeedback = () => {
    setStage(Stage.FeedbackEnded);
  }

  const finishTrial = () => {
    onFinish({
      name: trialName,
      correct: isCorrect,
      timeout: hasTimedOut,
      practice: isPractice,
    });
    setAnswer(null);
    setStage(Stage.Start);
  };

  const onTimeout = () => {
    onFinish({
      name: trialName,
      correct: false,
      timeout: true,
      practice: isPractice,
    });
    setHasTimedOut(false);
    setAnswer(null);
  };

  const dir = useLanguageDirection();

  return (
    <AspectRatioView>
      <div
        className={cls(
          s.trial,
          isSelectable && s.selectable,
          isExplanationVisible && s.explanationVisible,
        )}
      >
        <div className={s.gameArea}>
          <div
            className={s.patternImage}
            style={{ backgroundImage: `url("${patternImage}")` }}
          />
          <div className={s.matches}>
            {selectableImages.map((image, index) => (
              <div
                key={image}
                className={cls(
                  s.match,
                  index === answer && s.selected,
                  shouldShowResult && isSelectionWrong && s.incorrect,
                )}
                style={{ backgroundImage: `url("${image}")` }}
                onClick={() => selectAnswer(index)}
                data-cy='puzzlesImage'
              />
            ))}
          </div>
        </div>
        {showTimer && <Timer>{remainingTime}</Timer>}
        {explanation && (
          <div className={s.explanation} dir={dir}>
            <ReactMarkdown>{explanation}</ReactMarkdown>
          </div>
        )}
        <div className={s.buttonContainer}>
          <GameSkipper className={s.skipButton} />
          <ButtonRect
            isVisible={isConfirmBtnVisible}
            text={lang.buttonConfirmAnswer}
            type={ButtonType.SUBMIT}
            className={s.buttonConfirm}
            onClick={confirmAnswer}
          />
          <ButtonRect
            isVisible={isNextBtnVisible}
            text={lang.buttonNext}
            type={ButtonType.PRIMARY}
            className={s.buttonNext}
            onClick={finishTrial}
          />
        </div>
      </div>
      <Toast
        onEnd={endFeedback}
        isVisible={isFeedbackVisible}
        duration={shouldShowResult ? 1000 : 1}
      >
        {
          shouldShowResult ? (
            <Toast.Message
              messageType={isCorrect ? GameMessageType.CORRECT : GameMessageType.INCORRECT}
            />
          ) : <p />
        }
      </Toast>
      <Toast
        onEnd={onTimeout}
        isVisible={isTimeoutInfoVisible}
        duration={5000}
      >
        <Toast.Message
          messageType={GameMessageType.TIME_OUT}
        />
      </Toast>
    </AspectRatioView>
  );
};

export default HMTTrial;
