import { useMemo } from 'react';
import classnames from 'classnames';

import { Language, Occupation } from 'types';
import { Tag } from 'components/assessment';
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import { ReactComponent as Leaf } from 'assets/images/leaf.svg';

import s from './CareerOptionCard.module.scss';
import links from './links';
import iconTypes from './iconTypes';
import { getCareerOptionLearnMoreInteraction } from '../../../utils';
import greenOccupations from './greenOccupations';
import { useTranslation } from 'appState';

const composeSearchQuery = (job: string, extraKeyword: string, language: Language) =>
  `https://www.google.com/search?q=${job}%20${extraKeyword}&lr=lang_${language.toLowerCase()}`;

type Props = {
  occupation: Occupation;
  basedOn: 'skillStrengths' | 'careerInterest';
  language: Language;
};

const CareerOptionCard: React.FC<Props> = ({
  occupation,
  basedOn,
  language,
}) => {
  const { addSearchQuery, incrementInteraction } = useAssessmentStateActions();
  const lang = useTranslation('CareerOptionCard');
  const { name, description } = (
    lang.occupations?.[occupation] ?? {name: 'unknown', description: 'unknown'}
  );

  const link = links[occupation];
  const tag = lang.tags[basedOn];
  const interactionSection = basedOn === 'careerInterest'
    ? 'careerInterestsInteractions'
    : 'careerSkillStrengthsInteractions';
  const interactionName = useMemo(() =>
    getCareerOptionLearnMoreInteraction(occupation), [occupation]);
  const isGreenOccupation = useMemo(() =>
    greenOccupations.includes(occupation), [occupation]);
  return (
    <section className={s.card}>
      <div className={s.topBar}>
        <Tag className={s.tag}>{tag}</Tag>
        <span className={classnames(s.icon, s[iconTypes[occupation]])} />
      </div>
      <h3 className={s.name}>{name}</h3>
      <p className={s.description}>{description}</p>
      <div className={s.learnMoreContainer}>
        <a
          href={link}
          className={s.learnMore}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            incrementInteraction(interactionSection, interactionName);
          }}
        >
          {lang.learnMore}
        </a>
        { isGreenOccupation && <Leaf className={s.leafIcon} /> }
      </div>
      <a
        href={composeSearchQuery(occupation, lang.searchQueryKeyword, language)}
        className={s.searchButton}
        target="_blank"
        rel="noreferrer"
        onClick={() => addSearchQuery(occupation)}
      >
        {lang.search}
      </a>
    </section>
  );
};

export default CareerOptionCard;
