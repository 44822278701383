import { Occupation } from 'types';

export enum RiskBehavior {
  RiskAverse = 'risk averse',
  RiskSeeker = 'risk seeker',
  RiskNeutral = 'risk neutral',
}

export type FacetScales = {
  assertiveness: number;
  calm: number;
  compassion: number;
  creativeImagination: number;
  energyLevel: number;
  happy: number;
  intellectualCuriosity: number;
  organization: number;
  productiveness: number;
  respectfulness: number;
  responsibility: number;
  sociability: number;
  trust: number;
};

export type AssessmentResults = {
  date: string;

  cognitive: {
    memory: number | null;
    adaptability: number | null;
    attention: number | null;
    problemSolving: number | null;
    speed: number | null;
    persistence: number | null;
    // persistence: boolean;
  },

  behavior: {
    proactivity: number | null;
    riskBehavior: RiskBehavior | null,
    growthMindset: number | null;
  },

  emotionalIntelligence: {
    empathy: number | null;
    selfAwareness: number | null;
    selfManagement: number | null;
    socialAwareness: number | null;
    relationshipManagement: number | null;
  },

  personality: {
    conscientiousness: number;
    emotionalStability: number;
    extraversion: number;
    agreeableness: number;
    openness: number;
    facetScales: FacetScales;
  } | null,

  occupationalInterests: {
    realistic: number;
    investigative: number;
    artistic: number;
    social: number;
    enterprising: number;
    conventional:number;
  } | null,

  occupations: {
    bySkills: Occupation[],
    byInterests: Occupation[],
  },

  extraGameResults: {
    artistic: number;
    conventional: number;
    enterprising: number;
    investigative: number;
    persistence: number;
    realistic: number;
    social: number;
  },
};
