import { useCallback, useState } from 'react';

import { useLanguage, useAppState } from 'appState';
import { Language } from 'types';

import arrowDown from './arrow-down.svg';
import s from './LanguageSwitch.module.scss';

const LanguageSwitch: React.FC = () => {
  const language = useLanguage();
  const [isOnpen, setIsOpen] = useState<boolean>(false);
  const toggleSwitch = useCallback(() => setIsOpen(!isOnpen), [isOnpen]);
  const appState = useAppState();

  const switchLanguage = useCallback(
    (lang: Language) => {
      appState.setLanguage(lang);
    },
    [appState],
  );

  const label = {
    'English': 'English',
    'Amharic': 'አማርኛ',
    'Italian': 'italiano',
    'Arabic': 'العربية',
  }

  return (
    <>
      <div className={s.languageBtn} onClick={toggleSwitch}>
        {label[language]}
        <img src={arrowDown} alt="arrow down icon" />
        {isOnpen && (
          <ul className={s.languageOptions}>
            <button type="button" onClick={() => switchLanguage('English')}>
              {label['English']}
            </button>
            <button type="button" onClick={() => switchLanguage('Amharic')}>
              {label['Amharic']}
            </button>
            <button type="button" onClick={() => switchLanguage('Italian')}>
              {label['Italian']}
            </button>
            <button type="button" onClick={() => switchLanguage('Arabic')}>
              {label['Arabic']}
            </button>
          </ul>
        )}
      </div>
    </>
  );
};

export default LanguageSwitch;
