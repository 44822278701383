import { useCallback, useState } from 'react';

import { fillTranslationTextWithVariables } from 'hooks';
import { getDeviceInfo } from 'utils';
import { GameId } from 'types/enums';
import { QuizzResults } from 'types';
import { useAppStateActions, useTranslation } from 'appState';
import {
  GameFlow,
  Instructions,
  Intro,
  Quizz,
} from 'components/game';

import { PPResults } from './types';
import Feedback from '../../components/game/Feedback';
import { calcProactivePersonalityScore } from '../../utils/scoresCalc';

const PPGameFlow: React.FC = () => {
  const { gameFlowForward, submitGameResults } = useAppStateActions();
  const lang = useTranslation('PP');
  const [gameScore, setGameScore] = useState<number>();

  const onComplete = useCallback((scores: QuizzResults) => {
    const results: PPResults = {
      proactiveTestScores: scores,
      proactiveTestMetadata: {
        device: getDeviceInfo(),
      },
    };
    submitGameResults(GameId.PP, results);
    setGameScore(calcProactivePersonalityScore(results.proactiveTestScores) * 100);
    gameFlowForward();
  }, [submitGameResults, gameFlowForward]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.title}
            description={lang.description}
            buttonLabel={lang.buttonLabels.intro}
            superPowerName={lang.superPowerName}
            superPower={lang.superPower}
          />
        ),
        () => (
          <Instructions
            name={lang.title}
            instructions={lang.instructions}
            nextButtonLabel={lang.buttonLabels.instructions}
          />
        ),
        () => (
          <Quizz
            questions={lang.questions}
            setSize={2}
            scoreLabels={lang.scoreLabels}
            onComplete={onComplete}
          />
        ),
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { score: gameScore || 0 },
              )
            }
          />
        ),
      ]}
    </GameFlow>
  );
};

export default PPGameFlow;
export type Results = PPResults;
