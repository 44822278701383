import { useMemo } from 'react';
import { Section, Tab } from 'AppFlow/Summary/Assessment/enums';
import useSectionSelection from 'AppFlow/Summary/Assessment/useSectionSelection';
import { useAppStateActions, useAssessmentVisibility, useTranslation } from 'appState';
import { useAssessmentStateActions } from 'AppFlow/Summary/Assessment/state';
import { getLearningCenterSectionName } from 'utils';
import { useSkillStrengthCardStateActions } from '../SkillStrengthCard/state/selectors';
import s from './ChartItemPersonality.module.scss';

type Props = {
  name: string;
  labels?: string[],
  description?: string;
  descriptionLeft?: string[];
  descriptionRight?: string[];
  value: number;
  order?: number;
  barColor?: string;
  type?: string;
};

const ChartItemPersonality: React.FC<Props> = ({
  name,
  labels,
  descriptionLeft,
  descriptionRight,
  value,
  order = 0,
  barColor,
  type,
}) => {
  const lang = useTranslation('ChartItemPersonality');
  const { onSectionSelected, activeTab } = useSectionSelection();
  const { setSelectedAssessmentLearningSection } = useAppStateActions();
  const { incrementInteraction } = useAssessmentStateActions();
  const { toggleSkillStrengthCard } = useSkillStrengthCardStateActions();
  const { showFeedback } = useAssessmentVisibility();

  const sectionName = useMemo<string>(() => {
    if (type) {
      return getLearningCenterSectionName(type);
    }
    return getLearningCenterSectionName(name);
  }, [name, type]);

  return (
    <div className={s.item}>
      <div className={s.labelWrapper}>
        {labels?.length && (
          <>
            <p style={{ fontWeight: value < 0.5 ? 'bold' : 'normal' }}>{labels[0]}</p>
            <p style={{ fontWeight: value >= 0.5 ? 'bold' : 'normal' }}>{labels[1]}</p>
          </>
        )}
      </div>
      <div className={s.barWrapper}>
        <div
          className={s.barValue}
          style={{
            width: `${value * 100}%`,
            animationDelay: `calc(200ms + ${order * 200}ms)`,
            ...barColor ? { backgroundColor: barColor } : {},
            opacity: 0.2 + (value * 0.8),
          }}
        />
      </div>

      <div className={s.descriptionWrapper}>
        <ul>
          {descriptionLeft?.map((description) => <li key={description}>{description}</li>)}
        </ul>
        <ul>
          {descriptionRight?.map((description) => <li key={description}>{description}</li>)}
        </ul>
      </div>
      {
        showFeedback
        && activeTab !== Tab.Entrepreneurship
        && (
          <div
            className={s.link}
            onClick={() => {
              toggleSkillStrengthCard(false);
              onSectionSelected(Section.CareerLearningCenter);
              setSelectedAssessmentLearningSection(sectionName);
              const event = `learnMoreRedirection${sectionName}`;
              incrementInteraction('mainFeedbackInteractions', event);
            }}
          >
            {lang.freeCourses}
          </div>
        )
      }
    </div>
  );
};

export default ChartItemPersonality;
