import { useLanguageDirection } from 'appState';
import ScoreSelector from './ScoreSelector';
import s from './QuizzItem.module.scss';

type QuizzItemProps = {
  question: string;
  image?: string;
  scoreLabels: string[];
  onAnswer: (answer: number) => void;
};

const QuizzItem: React.FC<QuizzItemProps> = ({
  question,
  image,
  onAnswer,
  scoreLabels,
}) => {
  const dir = useLanguageDirection();

  return (
    <div className={s.quizzItem}>
      <p className={s.question} dir={dir}>{question}</p>
      {
        image && (
          <div
            className={s.image}
            style={{ backgroundImage: `url(${image})` }}
          />
        )
      }
      <ScoreSelector onSelect={onAnswer} scoreLabels={scoreLabels} />
    </div>
  );
}

export default QuizzItem;
